import { IMagnoliaContext } from '@magnolia/template-annotations/src/service/GetMagnoliaContext';

import StaticPage from '@/templates/pages/StaticPage';
import StaticPageLayout from '@/Layouts/StaticPageLayout';
import Footer from '@/Layouts/Footer/Footer';
import Header from '@/Layouts/Header/Header';
import Teaser from '@/components/teaser/Teaser';
import Accordion from '@/components/accordion/Accordion';
import Image from '@/components/image/Image';
import Headline from '@/components/Headline';
import RichText from '@/components/RichText/RichText';
import AccordionElement from '@/components/accordion/AccordionElement';
import Tabs from '@/components/tabs/Tabs';
import SimpleHeaderText from '@/components/SimpleHeaderText';
import FixedColumnLayout from '@/components/fixedColumnLayout/FixedColumnLayout';
import LeftNavi from '@/components/leftNavi/LeftNavi';
import Ibe from '@/components/ibe/Ibe';
import IbeSearch from '@/components/ibe/ibeSearch/IbeSearch';
import FeatureList from '@/components/featureList/FeatureList';
import Checkout from '@/components/checkout/Checkout';
import LinkList from '@/components/linkList/LinkList';
import Link from '@/components/linkList/Link';
import Poster from '@/components/poster/Poster';
import Button from '@/components/button/Button';
import Quotes from '@/components/quotes/Quotes';
import ConsentButton from '@/components/consentButton/ConsentButton';
import AlertBox from '@/components/alertBox/AlertBox';
import BookingList from '@/components/bookingList/BookingList';
import BookingDetails from '@/components/booking/BookingDetails';
import Offer from '@/components/offer/Offer';
import Newsletter from '@/components/newsletter/Newsletter';

import OfferFixedColumnLayout from '@/components/offer/OfferFixedColumnLayout';
import PasswordChange from '@/components/password/PasswordChange';
import { ApiBreadCrumbResponse, ApiPage } from '../../api';
import YsnConfigModel from '@/Config/YsnConfigModel';
import { Hotel } from '@/types/cms/Hotel';
import { Destination } from '@/types/cms/Destination';
import Destinations from '@/components/Destinations/Destinations';

export const PUBLIC_CONFIG_NODE_PATH = '/fe-public-config';

export const magnoliaUrl: string = process.env.MAGNOLIA_HOST ?? '';
export const apis = {
  base: magnoliaUrl + (process.env.MAGNOLIA_API_BASE ?? '/.rest'),
  templateAnnotations:
    magnoliaUrl + (process.env.MAGNOLIA_API_TEMPLATES ?? '/.rest/template-annotations/v1'),
  pages: magnoliaUrl + (process.env.MAGNOLIA_API_PAGES ?? '/.rest/delivery/pages/v1'),
  hotels: magnoliaUrl + (process.env.MAGNOLIA_API_HOTELS ?? '/.rest/delivery/hotels-endpoint'),
  destinations:
    magnoliaUrl +
    (process.env.MAGNOLIA_API_DESTINATIONS ?? '/.rest/delivery/destinations-endpoint'),
  config: magnoliaUrl + (process.env.MAGNOLIA_API_CONFIG ?? '/.rest/delivery/config/')
};

export function apiFetch(url: string): ReturnType<typeof fetch> {
  if (!process.env.MAGNOLIA_AUTH_USER || !process.env.MAGNOLIA_AUTH_PASSWORD) {
    return fetch(url);
  }
  const credentials = Buffer.from(
    process.env.MAGNOLIA_AUTH_USER + ':' + process.env.MAGNOLIA_AUTH_PASSWORD
  ).toString('base64');
  return fetch(url, { headers: { Authorization: `Basic ${credentials}` } });
}

export const rootNodePath: string = process.env.MAGNOLIA_SITE_PATH ?? '';

interface errorMsg {
  code: string;
  message: string;
}

export interface MagnoliaNode {
  '@name': string;
  '@path': string;
  '@id': string;
  '@nodeType': string;
  '@nodes': { [key: string]: string };
  '@index'?: number;
}

export interface MagnoliaPageNode extends MagnoliaNode {
  'mgnl:created': string;
  'mgnl:lastModified': string;
  'mgnl:template'?: string;
  error?: errorMsg;
}

export interface MagnoliaAreaNode extends MagnoliaNode {
  'mgnl:created': string;
  'mgnl:lastModified': string;
}

export interface MagnoliaComponentNode extends MagnoliaPageNode {}

export interface MagnoliaDamNode extends MagnoliaNode {
  '@link': string; // TODO: maybe move this to MagnoliaNode
  metadata: {
    fileName: string;
    fileSize: number;
    format: string;
    mimeType: string;
    width: number;
    height: number;
    title?: string;
    caption?: string;
  };
  renditions?: {
    [rendition: string]: {
      mimeType: string;
      link: string;
    };
  };
}

export type LinkSwitchableMixin = {
  // TODO: find a better way for switchable fields
  linkType: 'page' | 'external' | 'download';
  linkTypepage?: string;
  linkTypedownload?: {
    '@name': string;
    '@path': string;
    '@id': string;
    '@link': string;
  };
  linkTypeexternal?: string;
};

export type customImageSizeSwitchableMixin = {
  imageSize: 'original' | 'thumbnail' | 'small' | 'medium' | 'large' | 'square' | 'custom';
  // CamelCase is not working here due to the naming in CMS, same for LinkSwitchableMixin
  imageSizecustomImageWidth: number;
  imageSizecustomImageHeight: number;
};

export interface MagnoliaMappingConfig {
  layoutMappings: { [key: string]: React.FC<any> };
  componentMappings: { [key: string]: React.FC<any> };
}

export interface HeaderNavTypeChild extends MagnoliaNode {
  breadCrumbs?: boolean;
  title: string;
  url: string;
}

export type HeaderNavType = { [a: string]: HeaderNavType } & HeaderNavTypeChild;

// const keys = [...Array(50).keys()] as const;
// type DigitType = typeof keys[number];
// type Images = `images${DigitType}`;

// IBE Components
export const ibeSearchComponent = 'website:components/ibesearch';
export const ibeComponent = 'website:components/ibe';
export const checkoutComponent = 'website:components/checkout';
export const offerComponent = 'website:components/offer';

export const config: MagnoliaMappingConfig = {
  layoutMappings: {
    'website:pages/static': StaticPageLayout
  },
  componentMappings: {
    'website:pages/static': StaticPage,

    'website:components/headline': Headline,
    'website:components/richText': RichText,
    'website:components/image': Image,
    'website:components/footer': Footer,
    'website:components/header': Header,
    'website:components/accordion': Accordion,
    'website:components/accordionElement': AccordionElement,
    'website:components/tabs': Tabs,
    'website:components/simpleHeaderText': SimpleHeaderText,
    'website:components/fixedColumnLayout': FixedColumnLayout,
    'website:components/leftNavi': LeftNavi,
    'website:components/featureList': FeatureList,
    'website:components/teaser': Teaser,
    'website:components/link': Link,
    'website:components/linkList': LinkList,
    'website:components/poster': Poster,
    'website:components/button': Button,
    'website:components/quotes': Quotes,
    'website:components/consentButton': ConsentButton,
    'website:components/alertBox': AlertBox,
    'website:components/bookingList': BookingList,
    'website:components/bookingDetails': BookingDetails,
    [offerComponent]: Offer,
    'website:components/offerFixedColumnLayout': OfferFixedColumnLayout,
    'website:components/newsletter': Newsletter,
    'website:components/passwordChange': PasswordChange,
    [ibeComponent]: Ibe,
    [ibeSearchComponent]: IbeSearch,
    [checkoutComponent]: Checkout,
    'website:components/destinations': Destinations
  }
};

export interface Props {
  magnoliaContext: IMagnoliaContext;
  rootNodePath: string;
  page: MagnoliaPageNode;
  headerNav: ApiPage[];
  templateAnnotations?: Object;
  config: MGNLConfigType;
  hasIbeComponent: boolean;
  hasCheckoutComponent: boolean;
  breadCrumb: ApiBreadCrumbResponse['nodes'];
  cmsHotels: Hotel[];
  cmsDestinations: Destination[];
}

export type MGNLConfigType = MagnoliaNode & Partial<YsnConfigModel>;
