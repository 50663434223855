import { useEffect, useState, useRef } from 'react';
import debounce from 'lodash-es/debounce';

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const debouncedScroll = useRef(
    debounce(async () => {
      setScrollPosition(window.scrollY);
    }, 200)
  ).current;

  useEffect(() => {
    window.addEventListener('scroll', debouncedScroll);
    debouncedScroll();
    return () => {
      window.removeEventListener('scroll', debouncedScroll);
      debouncedScroll.cancel();
    };
  }, []);

  return scrollPosition;
};

export default useScrollPosition;
