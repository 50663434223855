import { observer } from 'mobx-react';
import React from 'react';
import CheckoutStore from '@/components/checkout/CheckoutStore';
import { ApiComponentType, ApiInsurance, ApiItemType, ApiService, ApiServiceUnit } from '@ibe/api';
import useTranslationMGL from '@/Util/useTranslationMgl';
import Keys from '@/Translations/generated/en/Checkout.json.keys';
import OptionalServiceCard from '@/components/checkout/OptionalServiceCard';

export interface OptionalInsuranceServicesProps {
  store: CheckoutStore;
}

const OptionalInsuranceServices = observer(function OptionalInsuranceServices(
  props: OptionalInsuranceServicesProps
) {
  const { store } = props;

  const components =
    store.packageCart?.packageModel.packageDetails &&
    store.packageCart?.packageModel.packageDetails[0].components;
  const insuranceComponents =
    components &&
    components.filter(
      component =>
        component.itemType === ApiItemType.INSURANCE &&
        component.componentType === ApiComponentType.OPTIONAL
    );

  const handleSelectInsurance = async (componentId: string, insuranceUnitId: string) => {
    await store.packageCartAddItem(componentId, insuranceUnitId);
    await store.attemptBooking();
  };

  const handleRemoveInsurance = async (componentId: string, insuranceUnitId: string) => {
    await store.packageCartRemoveItem(componentId, insuranceUnitId);
    await store.attemptBooking();
  };

  return (
    <>
      {insuranceComponents?.map(component => {
        return component.selectableItems.map(service => {
          const insurance = service as ApiInsurance;
          return insurance.units.map(unit => {
            return (
              <OptionalServiceCard
                key={insurance.id + unit.id}
                component={component}
                service={service as ApiService}
                unit={unit as ApiServiceUnit}
                handleRemoveService={handleRemoveInsurance}
                handleSelectService={handleSelectInsurance}
              />
            );
          });
        });
      })}
    </>
  );
});

export default OptionalInsuranceServices;
