import React from 'react';
import styles from './Headline.module.scss';

interface Props {
  text: string;
  type: 'h1' | 'h2' | 'h3' | 'h4';
  addDivider?: boolean;
}

const Headline = (props: Props): JSX.Element => {
  const { text, type, addDivider } = props;
  const HtmlTag = type || 'h2';
  return (
    <HtmlTag className={addDivider ? styles.headlineDivider : ''}>
      <span className="headline__text">{text}</span>
    </HtmlTag>
  );
};

export default Headline;
