import { Login, useAuth } from '@ibe/components';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import React, { MouseEventHandler } from 'react';
import { ModalProps } from 'reactstrap/es/Modal';
import Keys from '@/Translations/generated/en/login-form.json.keys';
import useTranslationMGL from '@/Util/useTranslationMgl';
import LoginForm from '@/Layouts/Header/LoginForm';

interface Props extends ModalProps {
  onSuccess?: () => void;
  onFailure?: () => void;
  onForgotPassword?: () => void;
}

const LoginModal = (props: Props): JSX.Element => {
  const { persistToken } = useAuth();
  const { t } = useTranslationMGL('login-form');

  const onSuccess = async () => {
    persistToken();
    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  const modalClasses = `login-modal ${props['className'] ? props['className'] : ''}`;

  return (
    <Modal
      isOpen={props.isOpen}
      className={modalClasses}
      modalClassName="login-modal-container"
      container={document.getElementById('iso') || undefined}
    >
      <ModalHeader toggle={props['toggle'] as MouseEventHandler}>{t(Keys.headline)}</ModalHeader>
      <ModalBody>
        <Login
          Form={LoginForm}
          onPasswordChangeRequired={() => {}}
          onSuccess={onSuccess}
          withCaptcha
          hasResetPassword
        />
      </ModalBody>
    </Modal>
  );
};

export default LoginModal;
