import { observable, action, makeObservable } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { CheckoutStep as Step } from '../Models/CheckoutStep';
import PageUrl from '@/Models/PageUrl';

class WorkflowService {
  steps: Step[] = [];

  currentStep: Step | null = null;

  constructor() {
    makeObservable(this, {
      steps: observable,
      currentStep: observable
    });
  }

  get currentStepIndex(): number {
    if (this.currentStep) {
      return this.steps.findIndex(s => s.slug === this.currentStep?.slug);
    }
    return 0;
  }

  public setSteps(steps: Step[]): void {
    this.steps = steps;
  }

  public setCurrentStep(step: Step | null): void {
    this.currentStep = step;
  }

  public get nextStepSlug(): string {
    const index = this.currentStepIndex;
    const nextIndex = index + 1;
    if (typeof this.steps[nextIndex] !== 'undefined') {
      return this.steps[nextIndex].slug || '';
    }
    return '';
  }

  public get prevStepSlug(): string {
    const index = this.currentStepIndex;
    const prevIndex = index - 1;
    if (typeof this.steps[prevIndex] !== 'undefined') {
      return this.steps[prevIndex].slug || '';
    }
    return PageUrl.HOME;
  }

  public slugByIndex(index: number): string {
    if (typeof this.steps[index] !== 'undefined') {
      return this.steps[index].slug || '';
    }
    return '';
  }
}

export default WorkflowService;
