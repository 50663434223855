import React, { useMemo } from 'react';
import { ApiBestPricePackageModel, ApiRoomContainer } from '@ibe/api';
import { PackageSearchParams, useAppService, useConfig } from '@ibe/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBed,
  faCalendar,
  faUser,
  faPlaneDeparture,
  faPlaneArrival,
  faBus,
  faPlusSquare
} from '@fortawesome/free-solid-svg-icons';
import Keys from '../../Translations/generated/en/package-details.json.keys';
import dayjs from 'dayjs';
import { hasInsurance, hasTransfer } from '../IncludedServicesPreview/IncludedServicesPreview';
import useTranslation from '@/Util/useTranslation';
import { Table } from 'reactstrap';

const PrintInfo = (props: {
  item: ApiBestPricePackageModel;
  searchParams: PackageSearchParams;
}): JSX.Element => {
  const { item, searchParams } = props;
  const { t } = useTranslation('package-details');
  const app = useAppService();
  const config = useConfig();

  const numberOfPersons = useMemo(() => {
    const adults =
      searchParams?.occupancy?.reduce((total: number, current: ApiRoomContainer) => {
        return total + (current.adults || 0);
      }, 0) || 0;
    const children =
      searchParams?.occupancy?.reduce((total: number, current: ApiRoomContainer) => {
        return total + (current.children || 0) + (current.infants || 0);
      }, 0) || 0;
    return {
      total: adults + children,
      adults,
      children
    };
  }, [searchParams]);

  const room = useMemo(() => {
    return item.rooms.find(room => !!room.defaultMeal) || item.rooms[0];
  }, [item]);

  const componentTypes = item.componentTypes;

  return (
    <div className="best-price-package-details__print-info hide-in-screen">
      <div>
        <div className="bestprice-price-details__content__container">
          <div className="bestprice-price-details__icon">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <div className="bestprice-price-details__content">
            <span className="mr-3">
              {`${numberOfPersons.total} ${t(Keys.person, {
                count: numberOfPersons.total
              })}`}
            </span>
            <span>
              {`${numberOfPersons.adults} ${t(Keys.adult, { count: numberOfPersons.adults })}, ${
                numberOfPersons.children
              } ${t(Keys.child, {
                count: numberOfPersons.children
              })}`}
            </span>
          </div>
        </div>
        <div className="bestprice-price-details__content__container">
          <div className="bestprice-price-details__icon">
            <FontAwesomeIcon icon={faCalendar} />
          </div>
          <div className="bestprice-price-details__content">
            <span className="mr-3">{`${dayjs(item.startDate).format(
              config.displayFormatDateShort[app.lang]
            )} - ${dayjs(item.endDate).format(config.displayFormatDateShort[app.lang])}`}</span>
            <span>{`${item.duration.duration} ${t(Keys.night, {
              count: item.duration.duration
            })}`}</span>
          </div>
        </div>
        <div className="bestprice-price-details__content__container">
          <div className="bestprice-price-details__icon">
            <FontAwesomeIcon icon={faBed} />
          </div>
          <div className="bestprice-price-details__content">
            <span className="mr-3">{room.description}</span>
            <span>{room.defaultMeal.description || ''}</span>
          </div>
        </div>
        {hasTransfer(componentTypes) && (
          <div className="bestprice-price-details__content__container">
            <div className="bestprice-price-details__icon">
              <FontAwesomeIcon icon={faBus} />
            </div>
            <div className="bestprice-price-details__content">
              <span className="mr-3">{t(Keys.included)}</span>
            </div>
          </div>
        )}
        {hasInsurance(componentTypes) && (
          <div className="bestprice-price-details__content__container">
            <div className="bestprice-price-details__icon">
              <FontAwesomeIcon icon={faPlusSquare} />
            </div>
            <div className="bestprice-price-details__content">
              <span className="mr-3">{t(Keys.included)}</span>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="bestprice-price-details__content__container">
          <div className="bestprice-price-details__icon">
            <FontAwesomeIcon icon={faPlaneDeparture} />
          </div>
          <div className="bestprice-price-details__content">
            <span className="mr-3">{t(Keys.outboundFlight)}</span>
            <span className="mr-2">{`${item.originName} ${item.origin}`}</span>
            <span>
              {dayjs(item.startDate).format(`ddd, ${config.displayFormatDate[app.lang]}`)}
            </span>
          </div>
        </div>
        <div className="bestprice-price-details__content__container">
          <div className="bestprice-price-details__icon">
            <FontAwesomeIcon icon={faPlaneArrival} />
          </div>
          <div className="bestprice-price-details__content">
            <span className="mr-3">{t(Keys.returnFLight)}</span>
            <span className="mr-2">{`${item?.geoAssignment?.geoUnit.name || ''} ${
              item?.geoAssignment?.geoUnit.code || ''
            }`}</span>
            <span>{dayjs(item.endDate).format(`ddd, ${config.displayFormatDate[app.lang]}`)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintInfo;

// PLEASE THESE WRAPPER IST NECESSARY FOR THE PRINT FUNCTION
export const PrintTableWrapper = ({ children }: { children: JSX.Element }) => {
  return (
    <Table className="print-table ">
      <tbody>
        <tr>
          <td>{children}</td>
        </tr>
      </tbody>
      <tfoot className="hide-in-screen">
        <tr>
          <td>
            <div className="print-footer-space">&nbsp;</div>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};
