import { ButtonProps } from 'reactstrap';
import { ElementType } from 'react';
import { LinkSwitchableMixin } from '@/Util/magnolia';
import { useConfig } from '@ibe/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

interface Props {
  label: string;
  isSecondaryStyle?: boolean;
  isFullWidth?: boolean;
  additionalClasses?: string;
}

const ConsentButton = (props: Props & LinkSwitchableMixin) => {
  const [attrs, Tag] = getButtonAttributes(props);
  const config = useConfig();
  if (!config.useConsentManager) return <></>;
  return (
    <Tag {...attrs}>
      <span>
        <FontAwesomeIcon icon={faCaretRight} />
        &nbsp;&nbsp;
        {props.label}
      </span>
    </Tag>
  );
};

const getButtonAttributes = (props: Props & LinkSwitchableMixin): [ButtonProps, ElementType] => {
  const { isSecondaryStyle, isFullWidth, additionalClasses } = props;
  const onClick = () => {
    window?.klaro && window?.klaro.show();
  };
  let attr: ButtonProps = {
    className: `iso__button btn btn-${isSecondaryStyle ? 'secondary' : 'primary'} ${
      isFullWidth ? 'w-100' : ''
    } ${additionalClasses ? additionalClasses : ''}`
  };
  attr = { ...attr, onClick: onClick };
  const Tag: ElementType = 'button';

  return [attr, Tag];
};

export default ConsentButton;
