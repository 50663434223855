import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';
import Head from 'next/head';
import { MagnoliaDamNode, MagnoliaPageNode } from '@/Util/magnolia';
import NavigateUpButton from '@/components/navigateUpButton/NavigateUpButton';
import CommunicationButton from '@/components/communicationModal/CommunicationButton';
import CommunicationModal from '@/components/communicationModal/CommunicationModal';
import { useAuth } from '@ibe/components';
import { configService } from '@/Config/config';

export interface Props {
  children: React.ReactNode;
  title: string;
  metaTitle?: string;
  metaDescription?: string;
  metaRobots: string[];
  socialMediaTitle?: string;
  socialMediaDescription?: string;
  socialMediaImage?: MagnoliaDamNode;
  // Magnolia metadata
  metadata: MagnoliaPageNode;
}

const DefaultPageLayout: FunctionComponent<Props> = observer((props: Props) => {
  const {
    title,
    metaTitle,
    metaDescription,
    metaRobots,
    socialMediaTitle,
    socialMediaDescription,
    socialMediaImage
  } = props;

  const auth = useAuth();
  const isAgencyLoggedIn = auth.authState.isLoggedIn;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <title>{metaTitle ?? title}</title>
        {metaDescription && <meta name="description" content={metaDescription} />}
        {/* TODO: <link rel="canonical" .../>*/}
        {metaRobots.length > 0 && <meta name="robots" content={metaRobots.join(',')} />}
        {socialMediaTitle && <meta property="og:title" content={socialMediaTitle} />}
        {socialMediaDescription && (
          <meta property="og:description" content={socialMediaDescription} />
        )}
        {socialMediaImage && (
          <meta
            property="og:image"
            content={socialMediaImage.renditions?.['1200']?.link ?? socialMediaImage['@link']}
          />
        )}
        {socialMediaImage &&
          (socialMediaImage.metadata.caption || socialMediaImage.metadata.title) && (
            <meta
              property="og:image:alt"
              content={socialMediaImage.metadata.caption ?? socialMediaImage.metadata.title}
            />
          )}
        {/* TODO: <link rel="apple-touch-icon" .../> */}
      </Head>
      <div className={'page__blank'} id="booking-summary-bar-container-anchor">
        {props.children}
      </div>
      <NavigateUpButton />
      {isAgencyLoggedIn && configService.get().showAgencyCommunication && (
        <div>
          <CommunicationButton
            onCommunicationButtonClickAction={() => {
              setIsModalOpen(true);
            }}
          />
          <CommunicationModal onDecline={() => setIsModalOpen(false)} isOpen={isModalOpen} />
        </div>
      )}
    </>
  );
});

DefaultPageLayout.defaultProps = {
  metaRobots: []
};

export default DefaultPageLayout;
