import { LinkSwitchableMixin, MagnoliaComponentNode, MagnoliaDamNode } from '@/Util/magnolia';
import { getNextLinkAttrs } from '@/components/linkList/Link';
import Link from 'next/link';
import React from 'react';
import ImageWrapper from '@/components/ImageWrapper';

interface Props {
  features: MagnoliaComponentNodeFeature & { [a: string]: MagnoliaComponentNodeFeatureItem };
}

interface MagnoliaComponentNodeFeature extends Omit<MagnoliaComponentNode, '@nodes'> {
  '@nodes': string[];
}

interface MagnoliaComponentNodeFeatureItem
  extends MagnoliaComponentNodeFeature,
    LinkSwitchableMixin {
  featureText: string;
  image: MagnoliaDamNode;
  openNewBrowserWindow?: boolean;
}

const FeatureList = (props: Props) => {
  const { features } = props;
  return (
    <div className="feature-list pt-4 pb-3 pb-sm-4 px-3 px-sm-4">
      <div className="feature-list__container">
        {features['@nodes'].map((item, index) => (
          <FeatureListItem key={index} {...features[item]} />
        ))}
      </div>
    </div>
  );
};

const FeatureListItem = (props: MagnoliaComponentNodeFeatureItem) => {
  const { image, featureText, linkTypedownload, linkTypeexternal, linkTypepage } = props;
  const linkAttrs = getNextLinkAttrs(props);

  const imageElement = (
    <div className="feature-list__iconBox">
      <ImageWrapper
        image={image}
        alt="icon"
        className="feature-list__icon"
        width={100}
        height={100}
      />
    </div>
  );

  return (
    <div className="feature-list__item">
      {(linkAttrs?.href && (linkTypedownload || linkTypeexternal || linkTypepage) && (
        <Link {...linkAttrs} locale={linkAttrs.download === true ? false : undefined}>
          {imageElement}
        </Link>
      )) ||
        imageElement}
      {featureText && <div className="feature-list__text">{featureText}</div>}
    </div>
  );
};

export default FeatureList;
