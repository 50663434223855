import { observable, action, runInAction, makeObservable } from 'mobx';

import pause from '../Util/pause';

enum Theme {
  STYLE_A,
  STYLE_B
}

class AppService {
  isLoading = true;

  theme = Theme.STYLE_A;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      theme: observable,
      setIsLoading: action,
      setTheme: action
    });
  }

  setIsLoading(b: boolean): void {
    this.isLoading = b;
  }

  setTheme(t: Theme): void {
    this.theme = t;
  }

  async init(): Promise<void> {
    await pause(500);
    runInAction(() => {
      this.isLoading = false;
    });
  }
}

export default AppService;
