import React, { useState } from 'react';

import { Button, Input, Row, Col } from 'reactstrap';
import useTranslationMGL from '@/Util/useTranslationMgl';
import Keys from '@/Translations/generated/en/CommunicationModal.json.keys';
import { LoggerFactory } from '@ibe/services';
import { Error, Info, LoadingOverlay, useApi, Warning } from '@ibe/components';
import { ApiActivity, ApiActivityType } from '@ibe/api';
import sanitize from '@/Util/sanitize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

interface YSNCommunicationModalProps {
  onDecline: () => void;
  isOpen: boolean;
}

const logger = LoggerFactory.get('CommunicationModal');

const CommunicationModal = (props: YSNCommunicationModalProps) => {
  const { onDecline, isOpen } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activitySuccess, setActivitySuccess] = useState<boolean>(false);
  const [activityError, setActivityError] = useState<boolean>(false);
  const [activityWarning, setActivityWarning] = useState<boolean>(false);

  const { t } = useTranslationMGL('CommunicationModal');
  const api = useApi();

  const [message, setMessage] = useState<string>('');

  const onDeclineAction = onDecline || logger.log('YSN_CM.onDecline');

  const handleAccept = (): void => {
    if (!message) {
      setActivityWarning(true);
      return;
    }
    sendCommunicationActivity(message);
  };

  const handleDecline = (): void => {
    setActivityWarning(false);
    setActivityError(false);
    setActivitySuccess(false);

    setMessage('');

    onDeclineAction();
  };

  function sendCommunicationActivity(activityText: string) {
    setIsLoading(true);
    setActivityWarning(false);
    setActivityError(false);
    setActivitySuccess(false);

    const activity: ApiActivity = {
      text: sanitize(activityText),
      type: ApiActivityType.CONTACT
    };

    api
      .createActivity(activity)
      .then(() => setActivitySuccess(true))
      .catch(() => setActivityError(true))
      .finally(() => {
        setMessage('');
        setIsLoading(false);
      });
  }

  return isOpen ? (
    <LoadingOverlay isLoading={isLoading}>
      <div className="communicationModal_container position-fixed">
        <div className="communicationModal_closeButton" onClick={handleDecline}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
        <div className="h3 font-weight-bold communicationModal_header">{t(Keys.header)}</div>
        <Row className="communicationModal_container_inner">
          <Col className="d-flex justify-content-between flex-column">
            <Row className="">
              <Input
                value={message}
                onChange={(evt): void => setMessage(evt.target.value)}
                type="textarea"
                className="communicationModal_input"
                placeholder={t(Keys.placeholder)}
              />
              {activitySuccess && (
                <div className="my-1">
                  <Info message={t(Keys.success)} />
                </div>
              )}
              {activityError && (
                <div className="my-1">
                  <Error message={t(Keys.error)} />
                </div>
              )}
              {activityWarning && (
                <div className="my-1">
                  <Warning message={t(Keys.warning)} />
                </div>
              )}
            </Row>
            <Row className="mt-2 justify-content-end communicationModal_buttons">
              <Button
                className="mr-2 text-decoration-none communicationModal_cancel-button"
                onClick={handleDecline}
                color="link"
              >
                {t(Keys.cancel)}
              </Button>
              <Button
                className="communicationModal_send-button"
                color="primary"
                onClick={handleAccept}
              >
                <span className="mx-5 my-1">{t(Keys.send)}</span>
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
    </LoadingOverlay>
  ) : (
    <div />
  );
};

export default CommunicationModal;
