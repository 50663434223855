import { ApiBookingSearch } from '@ibe/api';
import { encodeObjectasUrlParam, LoggerFactory, removeUndefinedProps } from '@ibe/services';

export interface RawBookingSearchParams {
  bookingNumber?: string;
  firstName?: string;
  lastName?: string;
  participantEmail?: string;
  travelDateStart?: string;
  travelDateEnd?: string;
  bookingDateStart?: string;
  bookingDateEnd?: string;
  bookingStates?: string;
  agent?: string;
}

export class BookingParamsTransformer {
  static decode(urlParams: RawBookingSearchParams): Partial<ApiBookingSearch> {
    const logger = LoggerFactory.get('BookingParamsTransformer');
    const params: Partial<ApiBookingSearch> = {};
    if (!urlParams) {
      return params;
    }
    try {
      params.bookingNumber = urlParams.bookingNumber || undefined;
      params.firstName = urlParams.firstName || undefined;
      params.lastName = urlParams.lastName || undefined;
      params.participantEmail = urlParams.participantEmail || undefined;
      params.travelDateStart = urlParams.travelDateStart || undefined;
      params.travelDateEnd = urlParams.travelDateEnd || undefined;
      params.bookingDateStart = urlParams.bookingDateStart || undefined;
      params.bookingDateEnd = urlParams.bookingDateEnd || undefined;
      params.bookingStates = JSON.parse(urlParams.bookingStates || '[]');
      params.agent = JSON.parse(urlParams.agent || '{}');
    } catch (e) {
      logger.error(e);
    }
    return removeUndefinedProps(params);
  }

  static encode(params: Partial<ApiBookingSearch>): string {
    const obj: RawBookingSearchParams = {
      bookingNumber: params.bookingNumber || undefined,
      firstName: params.firstName || undefined,
      lastName: params.lastName || undefined,
      participantEmail: params.participantEmail || undefined,
      travelDateStart: params.travelDateStart || undefined,
      travelDateEnd: params.travelDateEnd || undefined,
      bookingDateStart: params.bookingDateStart || undefined,
      bookingDateEnd: params.bookingDateEnd || undefined,
      bookingStates:
        params.bookingStates && params.bookingStates.length > 0
          ? JSON.stringify(params.bookingStates)
          : undefined,
      agent: params.agent && params.agent.lastName ? JSON.stringify(params.agent) : undefined
    };

    return encodeObjectasUrlParam(removeUndefinedProps(obj));
  }
}
