import React, { useEffect, useMemo, useState } from 'react';
import {
  ApiBaggageType,
  ApiComponentType,
  ApiFlightItem,
  ApiHotel,
  ApiHotelRoom,
  ApiInsurance,
  ApiItemType,
  ApiPrice,
  ApiPriceModifierType,
  ApiRoomContainer,
  ApiRoomRate,
  ApiSpecialRequest,
  ApiTransfer
} from '@ibe/api';
import Keys from '../../Translations/generated/en/package-details.json.keys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faBus, faPlane, faPlusSquare, faSuitcase } from '@fortawesome/free-solid-svg-icons';
import {
  DateDisplay,
  Overlay,
  OverlayContainer,
  Price,
  useAppService,
  useConfig,
  FlightSegmentDetailsPopup
} from '@ibe/components';
import dayjs from 'dayjs';
import {
  hasCartIncludedInsurance,
  hasCartIncludedTransfer,
  hasCartOptionalInsurance
} from '@/components/IncludedServicesPreview/CartIncludedServicesPreview';
import { useTheme } from '@/Util/useTheme';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import { observer } from 'mobx-react';
import LuggageLabelFactory from '@/Util/LuggageLabelFactory';
import CheckoutStore from '@/components/checkout/CheckoutStore';
import useTranslation from '@/Util/useTranslation';
import Promotion from './Promotion';

function getPersonCount(roomContainer: ApiRoomContainer): number {
  return roomContainer.adults + roomContainer.children + roomContainer.infants;
}

const CartPriceDetails = observer(function CartPriceDetails(props: {
  checkoutStore: CheckoutStore;
}): JSX.Element {
  const { checkoutStore } = props;
  const components = checkoutStore.packageCart?.packageModel?.packageDetails?.[0].components;
  const config = useConfig();
  const theme = useTheme();
  const app = useAppService();
  const { t } = useTranslation('package-details');
  const appService = useAppService();
  const luggageLabelFactory = new LuggageLabelFactory(t, Keys, appService.lang);

  const flightComponents = components?.filter(
    component => component.itemType === ApiItemType.FLIGHT
  );

  const outboundFlight =
    flightComponents && (flightComponents[0].selectedItems[0] as ApiFlightItem);
  const inboundFlight = flightComponents && (flightComponents[1].selectedItems[0] as ApiFlightItem);

  const outboundSegment = outboundFlight?.segment[0];
  const inboundSegment = inboundFlight?.segment[0];

  const outboundSegmentIncludedLuggages = useMemo(
    () =>
      outboundSegment?.baggage?.filter(
        v => v.type === ApiBaggageType.CHECKED || v.type === ApiBaggageType.CABIN
      ),
    [outboundSegment]
  );
  const inboundSegmentIncludedLuggages = useMemo(
    () =>
      inboundSegment?.baggage?.filter(
        v => v.type === ApiBaggageType.CHECKED || v.type === ApiBaggageType.CABIN
      ),
    [inboundSegment]
  );

  const outboundSegmentOptionalLuggage = useMemo(
    () => outboundSegment?.specialRequests?.filter(v => v.fee && v.fee.finalPrice > 0),
    [outboundSegment]
  );
  const inboundSegmentOptionalLuggage = useMemo(
    () => inboundSegment?.specialRequests?.filter(v => v.fee && v.fee.finalPrice > 0),
    [inboundSegment]
  );

  const getSelectedOptionalLuggage = (ssr: Array<ApiSpecialRequest> | undefined) => {
    return ssr
      ?.filter(v => v.travelerPosition && v.travelerPosition !== 0)
      .map(v => {
        v.name = luggageLabelFactory.create(ssr?.find(s => s.id === v.id && !!s.meta));
        return v;
      });
  };

  const selectedOutboundSegmentOptionalLuggage = useMemo(
    () => getSelectedOptionalLuggage(outboundSegmentOptionalLuggage),
    [outboundSegmentOptionalLuggage]
  );

  const selectedInboundSegmentOptionalLuggage = useMemo(
    () => getSelectedOptionalLuggage(inboundSegmentOptionalLuggage),
    [inboundSegmentOptionalLuggage]
  );

  const hotel = components?.find(component => component.itemType === ApiItemType.HOTEL)
    ?.selectedItems[0] as ApiHotel;

  const bookedHotelItems =
    checkoutStore.booking?.bookedItems.filter(
      bookedItem => bookedItem.itemType === ApiItemType.HOTEL
    ) || [];

  const hotelImgUrl = hotel.media?.mainImage?.extraSmall?.url || theme.placeholderImages?.hotel;

  const hasIncludedInsurance = hasCartIncludedInsurance(components || []);
  const hasOptionalInsurance = hasCartOptionalInsurance(components || []);
  const hasIncludedTransfer = hasCartIncludedTransfer(components || []);
  const optionalTransfers = components
    ?.filter(
      component =>
        component.itemType === ApiItemType.TRANSFER &&
        component.componentType === ApiComponentType.OPTIONAL
    )
    .flatMap(component => component.selectedItems);

  const regularAlternativeSelectedTransfer = components
    ?.filter(
      component =>
        component.itemType === ApiItemType.TRANSFER &&
        component.componentType === ApiComponentType.REQUIRED
    )
    .flatMap(component =>
      component.selectedItems.filter(service => {
        const transfer = service as ApiTransfer;
        return transfer.defaultService === false;
      })
    );
  const allOptionalTransfers =
    optionalTransfers?.concat(regularAlternativeSelectedTransfer || []) || [];

  const optionalInsurances =
    components
      ?.filter(
        component =>
          component.itemType === ApiItemType.INSURANCE &&
          component.componentType === ApiComponentType.OPTIONAL
      )
      .flatMap(component => component.selectedItems) || [];

  const [bookingTotalPrice, setBookingTotalPrice] = useState<ApiPrice | undefined>();
  const [discountAmount, setDiscountAmount] = useState<ApiPrice | undefined>();

  const discountsValue = useMemo(() => {
    if (checkoutStore.booking) {
      return checkoutStore.booking.price.modifiers
        .filter(m => m.type === ApiPriceModifierType.DISCOUNT)
        .map(value => value.absolute)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    }
    return 0;
  }, [checkoutStore.booking]);

  useEffect(() => {
    const price = checkoutStore.booking?.price ?? {};
    const bookingPrice = { ...price } as ApiPrice;

    if (bookingPrice?.finalPrice) {
      bookingPrice.finalPrice -= discountsValue;
    }
    setBookingTotalPrice(bookingPrice);
    if (discountsValue > 0) {
      setDiscountAmount({
        finalPrice: -discountsValue,
        currencyCode: checkoutStore.booking?.price?.currencyCode || '',
        modifiers: [],
        basePrice: 0,
        isInclusive: false,
        isStartingAt: false
      });
    } else {
      setDiscountAmount(undefined);
    }
  }, [discountsValue, checkoutStore.booking]);

  return (
    <div className="bestprice-price-details hide-in-print sticky-top">
      <div className="d-flex justify-content-between mb-3">
        <div className="bestprice-price-details__headline__container">
          <img src={hotelImgUrl} className="bestprice-price-details__image" />
        </div>
      </div>
      <div className="bestprice-price-details__main__container">
        {outboundSegment && inboundSegment && (
          <>
            <div className="bestprice-price-details__content__container">
              <div className="bestprice-price-details__icon">
                <FontAwesomeIcon icon={faPlane} />
              </div>
              <div className="bestprice-price-details__content">
                <div className="mb-2">{`${outboundSegment.origin.description} ${outboundSegment.origin.code} - ${outboundSegment.destination.description} ${outboundSegment.destination.code}`}</div>
                <div className="d-flex">
                  {outboundFlight && (
                    <FlightSegmentDetailsPopup flight={outboundFlight} iconButton />
                  )}
                  <span>
                    {`${dayjs(outboundSegment.departure).format(
                      config.displayFormatDate[app.lang]
                    )} ${outboundSegment.origin.code}`}{' '}
                    <DateDisplay utc date={outboundSegment.legs[0].departure} format={'HH:mm'} />
                  </span>
                  <span className="bestprice-price-details__flight-separator mx-2" />
                  <span>
                    {outboundSegment.destination.code || ''}{' '}
                    <DateDisplay
                      utc
                      date={outboundSegment.legs[outboundSegment.legs.length - 1].arrival}
                      format={'HH:mm'}
                    />
                  </span>
                </div>
                <div className="d-flex">
                  {inboundFlight && <FlightSegmentDetailsPopup flight={inboundFlight} iconButton />}
                  <span>
                    {`${dayjs(inboundSegment.departure).format(
                      config.displayFormatDate[app.lang]
                    )} ${inboundSegment.origin.code}`}{' '}
                    <DateDisplay utc date={inboundSegment.legs[0].departure} format={'HH:mm'} />
                  </span>
                  <span className="bestprice-price-details__flight-separator mx-2" />
                  <span>
                    {inboundSegment.destination.code}{' '}
                    <DateDisplay
                      utc
                      date={inboundSegment.legs[inboundSegment.legs.length - 1].arrival}
                      format={'HH:mm'}
                    />
                  </span>
                </div>
              </div>
            </div>
            {outboundSegmentIncludedLuggages &&
              outboundSegmentIncludedLuggages.length > 0 &&
              outboundSegmentIncludedLuggages.map(outboundIncludedLuggage => (
                <div
                  key={`${outboundIncludedLuggage.amount}${outboundIncludedLuggage.unit}${outboundIncludedLuggage.type}`}
                  className="bestprice-price-details__content__container"
                >
                  <div className="bestprice-price-details__icon">
                    <FontAwesomeIcon icon={faSuitcase} />
                  </div>
                  <div className="bestprice-price-details__content">
                    <div className="mb-2">{t(Keys.outboundIncludedLuggage)}</div>
                    <div className="d-flex">
                      <span>{luggageLabelFactory.createIncluded(outboundIncludedLuggage)}</span>
                    </div>
                  </div>
                </div>
              ))}
            {inboundSegmentIncludedLuggages &&
              inboundSegmentIncludedLuggages.length > 0 &&
              inboundSegmentIncludedLuggages.map(inboundIncludedLuggage => (
                <div
                  key={`${inboundIncludedLuggage.amount}${inboundIncludedLuggage.unit}${inboundIncludedLuggage.type}`}
                  className="bestprice-price-details__content__container"
                >
                  <div className="bestprice-price-details__icon">
                    <FontAwesomeIcon icon={faSuitcase} />
                  </div>
                  <div className="bestprice-price-details__content">
                    <div className="mb-2">{t(Keys.inboundIncludedLuggage)}</div>
                    <div className="d-flex">
                      <span>{luggageLabelFactory.createIncluded(inboundIncludedLuggage)}</span>
                    </div>
                  </div>
                </div>
              ))}
            {selectedOutboundSegmentOptionalLuggage &&
              selectedOutboundSegmentOptionalLuggage.length > 0 && (
                <div className="bestprice-price-details__content__container">
                  <div className="bestprice-price-details__icon">
                    <FontAwesomeIcon icon={faSuitcase} />
                  </div>
                  <div className="bestprice-price-details__content">
                    <div className="mb-2">{t(Keys.outboundOptionalLuggage)}</div>
                    {selectedOutboundSegmentOptionalLuggage.map((v, idx) => (
                      <div key={v.id || idx + (v.travelerPosition || idx)} className="d-flex">
                        <span>{v.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            {selectedInboundSegmentOptionalLuggage &&
              selectedInboundSegmentOptionalLuggage.length > 0 && (
                <div className="bestprice-price-details__content__container">
                  <div className="bestprice-price-details__icon">
                    <FontAwesomeIcon icon={faSuitcase} />
                  </div>
                  <div className="bestprice-price-details__content">
                    <div className="mb-2">{t(Keys.inboundOptionalLuggage)}</div>
                    {selectedInboundSegmentOptionalLuggage.map((v, idx) => (
                      <div key={v.id || idx + (v.travelerPosition || idx)} className="d-flex">
                        <span>{v.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </>
        )}
        <div className="bestprice-price-details__content__container">
          <div className="bestprice-price-details__icon">
            <FontAwesomeIcon icon={faBed} />
          </div>
          <div className="bestprice-price-details__content">
            <>
              <div>{hotel.name}</div>
              {bookedHotelItems.map(bookedHotelItem => {
                const assignedBookedRoomRate = checkoutStore.booking?.items.find(
                  item => item.id === bookedHotelItem?.idParent
                ) as ApiRoomRate;

                const assignedBookedRoom = checkoutStore.booking?.items.find(
                  item => item.id === assignedBookedRoomRate?.idParent
                ) as ApiHotelRoom;
                const occupancy = Object.keys(bookedHotelItem.priceByPersonId).length;
                return (
                  <div key={bookedHotelItem.id} className="mt-2">
                    <div>{assignedBookedRoom?.name}</div>
                    <div>
                      {assignedBookedRoomRate?.mealTypeDescription && (
                        <div>{assignedBookedRoomRate?.mealTypeDescription}</div>
                      )}
                      <div className="d-flex justify-content-between">
                        <div>
                          {`${occupancy} ${t(Keys.person, {
                            count: occupancy
                          })}`}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          </div>
        </div>
        {hasIncludedTransfer ||
        allOptionalTransfers.length > 0 ||
        hasOptionalInsurance ||
        hasIncludedInsurance ? (
          <>
            <div className="bestprice-price-details__divider" />
            <div className="bestprice-price-details__components">
              {(hasIncludedTransfer || allOptionalTransfers.length > 0) && (
                <div className="bestprice-price-details__content__container">
                  <div className="bestprice-price-details__icon">
                    <FontAwesomeIcon icon={faBus} />
                  </div>
                  <div className="bestprice-price-details__content">
                    <div className="mb-2">{t(Keys.transfers)}</div>
                    {hasIncludedTransfer && <div>{t(Keys.transferIncluded)}</div>}
                    {allOptionalTransfers.map((item, index) => {
                      const transfer = item as ApiTransfer;
                      return (
                        <div
                          key={transfer.id}
                          className={classNames({
                            'd-flex justify-content-between': true,
                            'mt-2': hasCartIncludedTransfer || index > 0
                          })}
                        >
                          <div>{transfer.name + ' ' + transfer.units[0].description}</div>
                          <div className="text-primary align-self-end text-nowrap">
                            <Price price={transfer.units[0].price} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {(hasIncludedInsurance || hasOptionalInsurance) && (
                <div className="bestprice-price-details__content__container">
                  <div className="bestprice-price-details__icon">
                    <FontAwesomeIcon icon={faPlusSquare} />
                  </div>
                  <div className="bestprice-price-details__content">
                    <div className="mb-2">{t(Keys.insurance)}</div>
                    {hasIncludedInsurance && <div>{t(Keys.insuranceIncluded)}</div>}
                    {hasOptionalInsurance &&
                      optionalInsurances.map((item, index) => {
                        const insurance = item as ApiInsurance;
                        return (
                          <div
                            key={insurance.id}
                            className={classNames({
                              'd-flex justify-content-between': true,
                              'mt-2': hasIncludedInsurance || index > 0
                            })}
                          >
                            <div>{insurance.name + ' ' + insurance.units[0].name}</div>
                            <div className="text-primary align-self-end text-nowrap">
                              {insurance.units[0].price?.finalPrice === 0 ? (
                                <span className="iso__price__value">
                                  {t(Keys.percentagePriceInsuranceLabel)}
                                </span>
                              ) : (
                                <Price price={insurance.units[0].price} />
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              <Promotion store={checkoutStore} />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="bestprice-price-details__total">
        <div className="w-100 bestprice-price-details__total__price text-nowrap">
          <>
            {discountAmount ? (
              <>
                <Row className="justify-content-between border-bottom border-light bestprice-price-details__total__voucher">
                  <Col>
                    <span>{t(Keys.voucher)}</span>
                  </Col>
                  <Col className="text-right">
                    <Price price={discountAmount} displayInline />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col>
                    <span>{t(Keys.totalPrice)}</span>
                  </Col>
                  <Col className="text-right">
                    {!checkoutStore.isLoading && <Price price={bookingTotalPrice} displayInline />}
                  </Col>
                </Row>
              </>
            ) : (
              <OverlayContainer>
                <Price price={bookingTotalPrice} />
                {checkoutStore.isLoading && <Overlay />}
              </OverlayContainer>
            )}
          </>
        </div>
      </div>
    </div>
  );
});

export default CartPriceDetails;
