import { LinkSwitchableMixin, MagnoliaComponentNode } from '@/Util/magnolia';
import NextLink, { LinkProps } from 'next/link';

const HTTP = 'http://';
const HTTPS = 'https://';

export interface LinkTypeProps extends Omit<MagnoliaComponentNode, '@nodes'> {
  '@nodes': string[];
  title: string;
  openNewBrowserWindow?: boolean;
}
export type SwitchableLinkProps = LinkTypeProps & LinkSwitchableMixin;
const Link = (props: SwitchableLinkProps) => {
  const { title, linkType } = props;
  const linkAttrs = getNextLinkAttrs(props);
  return (
    <NextLink className={linkType} passHref {...linkAttrs}>
      <span>{title}</span>
    </NextLink>
  );
};
export type LinkPropsType = LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;
export const getNextLinkAttrs = (
  props: LinkSwitchableMixin & { openNewBrowserWindow?: boolean }
) => {
  const {
    linkType,
    openNewBrowserWindow,
    linkTypeexternal,
    linkTypedownload,
    linkTypepage
  } = props;

  let linkProps: LinkPropsType = {
    href: '/'
  };
  if (openNewBrowserWindow || linkType === 'download') {
    linkProps = { ...linkProps, target: '_blank', rel: 'noopener noreferrer' };
  }
  if (linkType === 'external') {
    const extLink =
      linkTypeexternal?.startsWith(HTTP) || linkTypeexternal?.startsWith(HTTPS)
        ? linkTypeexternal
        : `${HTTPS}${linkTypeexternal}`;
    linkProps = { ...linkProps, passHref: true, href: extLink };
  }
  if (linkType === 'download') {
    linkProps = {
      ...linkProps,
      passHref: true,
      href: linkTypedownload?.['@link'] || '',
      download: true
    };
  }
  if (linkType === 'page') {
    linkProps = { ...linkProps, href: linkTypepage || '' };
  }
  return linkProps;
};

export default Link;
