import { useContext } from 'react';
import { useLocalStore } from 'mobx-react';
import PackageService from '@/Services/PackageService';
import PackageServiceContext from '@/Contexts/PackageServiceContext';
import { useApi } from '@ibe/components';

function usePackageService(): PackageService {
  const context = useContext(PackageServiceContext);
  const packageService: PackageService = useLocalStore<PackageService>(() => context);

  packageService.api = useApi();

  return packageService;
}

export default usePackageService;
