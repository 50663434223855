import Link from 'next/link';
import sanitize from '@/Util/sanitize';
import { LinkSwitchableMixin, MagnoliaDamNode } from '@/Util/magnolia';
import { Button, Card, CardBody, CardTitle } from 'reactstrap';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { getNextLinkAttrs } from '../linkList/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import ImageWrapper from '@/components/ImageWrapper';
import RenditionEnum from '@/Util/RenditionEnum';

interface TeaserProps {
  showCTA: boolean;
  ctaLabel: string;
  description: string;
  headline: string;
  image: MagnoliaDamNode;
  openNewBrowserWindow?: boolean;
}

const Teaser = (props: TeaserProps & LinkSwitchableMixin) => {
  const { magnoliaContext } = useGlobalMGLProps() || {};
  const { isMagnoliaEdit, isMagnolia } = magnoliaContext || {};
  const linkAttrs = getNextLinkAttrs(props);

  if (isMagnolia || isMagnoliaEdit) {
    return <TeaserCard {...props} />;
  }
  return (
    <Link
      className="teaser-link"
      {...linkAttrs}
      locale={linkAttrs.download === true ? false : undefined}
    >
      <TeaserCard {...props} />
    </Link>
  );
};

const TeaserCard = (props: TeaserProps) => {
  const { ctaLabel, description, headline, showCTA, image } = props;
  return (
    <Card className={`w-100 iso__teaser`}>
      {image && (
        <ImageWrapper
          className="card-img-top"
          image={image}
          alt={description}
          rendition={RenditionEnum.R_1024}
        />
      )}
      <CardBody>
        <CardTitle tag={'h5'}>{headline}</CardTitle>
        <div
          className="card-text teaser-description"
          dangerouslySetInnerHTML={{ __html: sanitize(description, true) }}
        />
        {showCTA && (
          <div className="text-center mt-auto">
            <Button className="teaserButton w-100">
              <span>
                <FontAwesomeIcon icon={faCaretRight} />
                &nbsp;&nbsp;
                {ctaLabel}
              </span>
            </Button>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default Teaser;
