/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-10-28 17:37:25 */

const Keys = {
  from: 'from',
  nights: 'nights',
  roomOnly: 'roomOnly',
  with: 'with',
  choose: 'choose',
  additionalDatesAvailable: 'additionalDatesAvailable',
  departure: 'departure',
  person: 'person',
  person_plural: 'person_plural',
  room: 'room',
  room_plural: 'room_plural',
  showMore: 'showMore',
  showLess: 'showLess',
  accommodationCategoryA: 'accommodationCategoryA',
  accommodationCategoryB: 'accommodationCategoryB',
  accommodationCategoryH: 'accommodationCategoryH'
}; export default Keys;