import React from 'react';
import styles from './NavigateUpButton.module.scss';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { ScrollerService, useIsOnProductPage } from '@ibe/components';
import useScrollPosition from '../../Hooks/useScrollPositionHook';

const NavigateUpButton = () => {
  const scrollPosition = useScrollPosition();
  const { isOnProductPage } = useIsOnProductPage();

  return (
    <Button
      color="primary"
      id="navigateUpButtonComponent"
      className={` hide-in-print ${styles.navigateUpButton} ${
        scrollPosition > 0 ? styles.navigateUpButton__show : styles.navigateUpButton__hide
      } ${isOnProductPage && styles.navigateUpButton__alt}`}
      onClick={() => ScrollerService.scrollToTop()}
    >
      <FontAwesomeIcon icon={faArrowUp} />
    </Button>
  );
};

export default NavigateUpButton;
