import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { HeaderProps } from './Header';
import { useState } from 'react';
import { IsoDropdown, IsoDropdownItem } from '@ibe/components';
import Link from 'next/link';

interface Props {
  loginLabel: HeaderProps['loginLabel'];
  logoutLabel: HeaderProps['logoutLabel'];
  bookingListLabel?: HeaderProps['bookingListLabel'];
  bookingListLink?: HeaderProps['bookingListLink'];
  openLoginModal: () => void;
  logout: () => void;
  isLoggedIn?: boolean;
  firstname?: string;
  lastname?: string;
}

const LoginButton = (props: Props) => {
  const {
    loginLabel,
    bookingListLabel,
    bookingListLink,
    openLoginModal,
    logoutLabel,
    logout,
    isLoggedIn,
    firstname,
    lastname
  } = props;
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleLogoutClick = () => {
    logout();
  };
  return (
    <div className="login-button-dropdown-container">
      <IsoDropdown
        isOpen={showPopup}
        onDropdownToggle={isLoggedIn ? togglePopup : openLoginModal}
        title={isLoggedIn ? `${firstname}, ${lastname}` : loginLabel}
        icon={faUserCircle}
      >
        {bookingListLabel && bookingListLink && (
          <IsoDropdownItem tag={Link} href={bookingListLink} role="link">
            {bookingListLabel}
          </IsoDropdownItem>
        )}
        <IsoDropdownItem onClick={handleLogoutClick} role="button">
          {logoutLabel}
        </IsoDropdownItem>
      </IsoDropdown>
    </div>
  );
};

export default LoginButton;
