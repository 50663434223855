import { createContext, useContext } from 'react';

import AppService from '../Services/AppService';
import WorkflowService from '../Services/WorkflowService';
import { UsePaymentResponse } from '@/Util/getPaymentStatus';

const appService = new AppService();
export const appContext = createContext(appService);

export type WorkflowContextType = {
  workflow: WorkflowService;
  setNextStepSlug: (nextStepSlug: string) => void;
  setPaymentResponse: (paymentResponse: UsePaymentResponse) => void;
  paymentResponse?: UsePaymentResponse;
};
const workflowService = new WorkflowService();
export const WorkflowContext = createContext<WorkflowContextType>({
  workflow: workflowService,
  setNextStepSlug: () => null,
  setPaymentResponse: () => null,
  paymentResponse: undefined
});

export const useWorkflowService = (): WorkflowContextType => {
  return useContext(WorkflowContext);
};
