/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-10-28 17:37:25 */

const Keys = {
  header: 'header',
  placeholder: 'placeholder',
  cancel: 'cancel',
  send: 'send',
  success: 'success',
  warning: 'warning',
  error: 'error'
}; export default Keys;