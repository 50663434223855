import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { observer, useLocalStore } from 'mobx-react';
import {
  CollapsibleRef,
  DateInputType,
  Overlay,
  PackageModule,
  PackageParams,
  PackageSearchStore,
  useApi,
  useClientPersistSearchParams,
  useConfig,
  useMediaQuery,
  useTheme,
  useWindow
} from '@ibe/components';
import {
  ApiDurationType,
  ApiGeoUnit,
  ApiGetPackagesResponse,
  ApiItemType,
  ApiListOptions,
  ApiProductData,
  ApiSortOption
} from '@ibe/api';
import { useRouter } from 'next/router';
import useQuery from '../../Util/useQuery';
import PackageParamsTransformer from '../../Util/ParamsTransformer/PackageParamsTransformer';
import { stepSessionStorage, validPromotionCodeStorage } from '@/Util/globals';
import RenderSearchContent from './render/RenderSearchContent';
import RenderSearch from './render/RenderSearch';
import RenderListItem from './render/RenderListItem';
import BestPriceSearchContent from './render/BestPriceSearchContent';
import { configService, productSearchSessionKey } from '@/Config/config';
import { IbeProps } from '@/components/ibe/IbeProps';
import useRemountHooks from '@/components/ibe/useRemountHook';
import PackageDetails from '@/components/PackageDetails/PackageDetails';
import PriceDetails from '@/components/PackageDetails/PriceDetails';
import { useMount } from 'react-use';

import {
  CustomPackageSearchStore,
  enrichSearchParamsCodes,
  fetchDestinationsGeoUnits,
  fetchDestinationsProducts,
  fetchOriginsGeoUnits,
  UrlEnrichmentData
} from '@/components/ibe/IbeUtils';
import { QueryUtils } from '@/Util/QueryUtils';
import Keys from '@/Translations/generated/en/ibe.json.keys';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import useTranslation from '@/Util/useTranslation';
import { PrintTableWrapper } from '../PackageDetails/PrintInfo';
import {
  ListOptionsParamsTransformer,
  RawListOptionsParams
} from '@/Util/ParamsTransformer/ListOptionsParamsTransformer';

const CustomIndexedDestinationSelect = dynamic(
  () => import('./render/CustomIndexedDestinationSelect'),
  {
    ssr: false
  }
);

const Ibe = observer(function Packages(props: IbeProps) {
  const { checkoutUrl } = props;
  const router = useRouter();
  const query = useQuery();
  const api = useApi();
  const config = useConfig();
  const theme = useTheme();
  const window = useWindow();
  const { t } = useTranslation('ibe');
  const persistentSearchParams = useClientPersistSearchParams<Partial<PackageParams>>() || {};
  const [searchClicked, setSearchClicked] = useState(true); // initially true, bc clicked on IbeSearch
  const [noChangeMessageShown, setNoChangeMessageShown] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [hasBookingError, setBookingError] = useState(false);
  const [urlEnrichmentData, setUrlEnrichmentData] = useState<UrlEnrichmentData | undefined>(
    undefined
  );
  const [searchParams, setSearchParams] = useState<Partial<PackageParams> | undefined>(undefined);
  const [expandForPrint, setExpandForPrint] = useState<boolean>(false);
  const isMounted = useRef<boolean>(false);
  const customPackageSearchStore = useLocalStore(() => new CustomPackageSearchStore(config, theme));
  customPackageSearchStore.setShowValidationErrorMessage(true);
  const isMobile = useMediaQuery({ query: '576', type: 'max' });

  useEffect(() => {
    customPackageSearchStore.validateParamsAndShowError();
  }, [isMobile]);

  const handlePrint = () => {
    setExpandForPrint(true);
    setTimeout(() => {
      window?.print();
    }, 500);
    setTimeout(() => {
      setExpandForPrint(false);
    }, 850);
  };

  const fetchDestination = async (onlyProduct?: boolean) => {
    const paramsDecoded = PackageParamsTransformer.decode(Object.fromEntries(query.entries()));
    const req = [
      fetchDestinationsProducts(api, paramsDecoded.destinations || []),
      onlyProduct ? null : fetchDestinationsGeoUnits(api)
    ];
    return Promise.all(req);
  };

  useMount(async () => {
    setLoading(true);
    const [productsRes, geoUnitsRes] = await fetchDestination();
    const origins = await fetchOriginsGeoUnits(api);
    setUrlEnrichmentData({
      destinations: {
        geoUnits: geoUnitsRes as ApiGeoUnit[],
        products: productsRes as ApiProductData[]
      },
      origins
    });
    isMounted.current = true;
    setLoading(false);
  });

  useEffect(() => {
    const updateTheSearchParams = async () => {
      if (!searchClicked) return;
      let newUrlEnrichmentData: UrlEnrichmentData | undefined = undefined;
      setLoading(true);
      const params = !!persistentSearchParams.getSearchParams
        ? persistentSearchParams.getSearchParams(router.asPath.split('?')[0])
        : undefined;
      let paramsDecoded = PackageParamsTransformer.decode(Object.fromEntries(query.entries()));

      paramsDecoded.dateInputType = DateInputType.FULL;
      if (paramsDecoded.mainServiceCodes?.length && isMounted) {
        const [productsRes] = await fetchDestination(true);
        newUrlEnrichmentData = {
          ...urlEnrichmentData,
          destinations: {
            ...urlEnrichmentData?.destinations,
            products: productsRes as ApiProductData[]
          }
        } as UrlEnrichmentData;
      }
      if (urlEnrichmentData) {
        paramsDecoded = enrichSearchParamsCodes(
          paramsDecoded,
          newUrlEnrichmentData || urlEnrichmentData
        );
      } else {
        setLoading(false);
        return;
      }
      const searchParameters =
        Object.keys(Object.fromEntries(query.entries())).length > 0
          ? {
              ...paramsDecoded,
              ...params
            }
          : { ...params };
      if (QueryUtils.areEqual(searchParameters, searchParams)) {
        setNoChangeMessageShown(true);
      } else {
        setSearchParams(searchParameters);
        setNoChangeMessageShown(false);
      }
      setLoading(false);
      setSearchClicked(false);
      if (newUrlEnrichmentData) {
        setUrlEnrichmentData(newUrlEnrichmentData);
      }
    };
    updateTheSearchParams();
  }, [!!persistentSearchParams.getSearchParams, query.toString(), JSON.stringify(urlEnrichmentData), searchClicked]);

  const updateUrlParams = (params: PackageParams, listOptions: ApiListOptions): void => {
    setLoading(true);
    params.preSelectedCharacteristics = undefined;
    const queryParams = PackageParamsTransformer.encode(params);
    if (!!persistentSearchParams.setSearchParams) {
      persistentSearchParams.setSearchParams(router.asPath.split('?')[0], params);
    }

    const newlistOptions = {
      ...({} as Partial<ApiListOptions>),
      pagination: listOptions.pagination
    };

    router
      .push(
        `${router.asPath.split('?')[0]}?${queryParams}&listOptions=${JSON.stringify(
          newlistOptions
        )}`
      )
      .finally(() => {
        setLoading(false);
        setSearchClicked(true);
      });
  };

  const handleBackHistory = () => {
    const initialHistoryState = history.state;
    history.back();

    setTimeout(() => {
      if (window && window?.history?.state === initialHistoryState) {
        if (searchParams) {
          const queryParams = PackageParamsTransformer.encode({
            ...searchParams,
            itemId: undefined,
            goToDetails: false
          });
          window.location.href = `${router.asPath.split('?')[0]}?${queryParams}`;
        }
      }
    }, 250);
  };

  const shallowUrlUpdate = (params: PackageParams): void => {
    const queryParams = PackageParamsTransformer.encode(params);
    if (!!persistentSearchParams.setSearchParams) {
      persistentSearchParams.setSearchParams(router.asPath.split('?')[0], params);
    }
    router.push(`${router.asPath.split('?')[0]}?${queryParams}`, undefined, { shallow: true });
  };

  const onBestPriceAvailabilitySelect = async (
    packageListResponse: ApiGetPackagesResponse | null,
    searchParams: PackageParams,
    packageCartId?: string
  ): Promise<void> => {
    if (packageCartId) {
      setLoading(true);
      try {
        await api.attemptBooking(packageCartId);
        stepSessionStorage.clear();
        validPromotionCodeStorage.clear();
        sessionStorage.setItem(productSearchSessionKey, query.toString());
        await router.push(checkoutUrl || '');
      } catch (err) {
        console.debug(err);
        setBookingError(true);
      }
      setLoading(false);
    }
  };

  const onPageChangeListener = useCallback(() => {
    const findSearchContainer = document.getElementsByClassName('iso__serviceList__mobile-section');
    if (window && findSearchContainer[0]) {
      const y = (findSearchContainer[0]?.getBoundingClientRect().top || 0) + window.pageYOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, []);

  function renderSearchWithMessage(
    CategorySelect: ReactNode,
    OriginSelect: ReactNode,
    DestinationSelect: ReactNode,
    TravelDatePicker: ReactNode,
    TravelMonthPicker: ReactNode,
    TravelerPicker: ReactNode,
    SearchButton: ReactNode,
    OriginCheckboxes: ReactNode,
    DateRangePickerSingleSelect: ReactNode,
    collapsibleRef?: CollapsibleRef
  ) {
    return RenderSearch(
      CategorySelect,
      OriginSelect,
      DestinationSelect,
      TravelDatePicker,
      TravelMonthPicker,
      TravelerPicker,
      SearchButton,
      OriginCheckboxes,
      DateRangePickerSingleSelect,
      collapsibleRef,
      noChangeMessageShown ? t(Keys.noSearchChangeMessage) : undefined,
      customPackageSearchStore
    );
  }

  const [keys] = useRemountHooks();

  function handleBackToListClick() {
    setLoading(true);
    handleBackHistory();
    setLoading(false);
  }

  const visibilityClassName = useMemo(() => {
    let paramsDecoded = PackageParamsTransformer.decode(Object.fromEntries(query.entries()));
    return isLoading && paramsDecoded.isNewTab && paramsDecoded.goToDetails
      ? 'hide_search_bar_on_new_tab'
      : '';
  }, [query.toString(), isLoading]);

  return (
    <PrintTableWrapper>
      <div className={visibilityClassName}>
        {searchParams?.itemId && (
          <Button className="hide-in-print mt-3 back-button" onClick={handleBackToListClick}>
            {t(Keys.backToList)}
          </Button>
        )}
        {!!searchParams && (
          <PackageModule
            listOptions={searchParams.listOptions}
            onBestPriceAvailabilitySelect={onBestPriceAvailabilitySelect}
            onSearch={updateUrlParams}
            onDetail={updateUrlParams}
            searchParams={searchParams}
            customPackageSearchStore={customPackageSearchStore}
            showLabels
            subType={ApiItemType.PREDEFINEDPACKAGE}
            showMultipleDurations={false}
            showTotalPriceOnly
            destinationMandatory
            endDateMandatory
            withDateRangePicker
            withAdvancedDateRangePicker
            withGroupRequestForm={false}
            withRoomCountSelector={configService.get().enableMultipleRooms}
            renderSearch={renderSearchWithMessage}
            renderSearchContent={RenderSearchContent}
            sortingType={{
              GROUP: [],
              SINGLE: [ApiSortOption.NAME, ApiSortOption.PRICE, ApiSortOption.PROMOTIONS]
            }}
            sorting={[ApiSortOption.NAME, ApiSortOption.PRICE, ApiSortOption.PROMOTIONS]}
            minDateRangeSpan={2}
            maxDateRangeSpan={200}
            renderListItem={RenderListItem}
            minSelectableChildAge={0}
            noDatePickerOverflow
            selectLabelSort
            withChildrenSelection
            bestPriceSubmitOnChange
            serviceSearchWithChildAgePicker
            changeOnBlur
            widgetClickable={true}
            packageOriginMustBeSet={false}
            packageOriginWithRemoveAll
            packageOriginWithSelectAll
            customIndexedDestinationSelect={(store: PackageSearchStore) => (
              <CustomIndexedDestinationSelect store={store} urlEnrichmentData={urlEnrichmentData} />
            )}
            dateRangeDurationProps={{
              withDuration: true,
              value: { duration: 7, type: ApiDurationType.DAY },
              usePlaceholderAsOption: true
            }}
            dateRangeUpdateStateFromProps={false}
            dateRangeFillWithLastDateRangeWhenNoSelection
            doBestPriceSearch
            bestPriceAfterSearch={shallowUrlUpdate}
            bestPriceMaxDateRangeSpan={42}
            bestPriceRenderSearchContent={BestPriceSearchContent}
            bestPriceDesktopSearchCollapse={false}
            bestPriceStickySearch
            ExternalBestPriceDetailsContent={{
              MainContent: props => <PackageDetails {...props} expandForPrint={expandForPrint} />,
              PriceDetails: props => <PriceDetails {...props} onPrintClick={handlePrint} />
            }}
            hideSearchInDetails
            bestPriceListSelectionsMode
            key={keys[0]}
            includeCheapestFlightInRoomPrice
            splitRequiredComponentPricePerPerson
            customSetIsLoading={setLoading}
            bestPriceShowDirectFlightsOnlyFilter={configService.get().showDirectFlightsFilter}
            customListOptions={{
              showFilterInModal: true,
              showApplyButtonInModal: true
            }}
            paginationProps={{
              onPageChangeListener
            }}
            showSearchParamsPerItem
            showDateSelectionInModal={isMobile}
            showPaxSelectionInModal={isMobile}
            paxSelectionRevalidateChildrenOnChangeAfterError
            paxSelectionValidateChildrenPaxSelectionOnLeave
            paxSelectionValidateChildrenPaxSelectionOnChange
            paxSelectionUpdatePaxOnLeaveWhenValid
            paxSelectionUpdatePaxOnChangeWhenValid
          />
        )}
        {searchParams?.itemId && (
          <Button className="hide-in-print back-button" onClick={handleBackToListClick}>
            {t(Keys.backToList)}
          </Button>
        )}
        <Modal
          isOpen={hasBookingError}
          toggle={() => setBookingError(!hasBookingError)}
          container={document.getElementById('iso') || undefined}
        >
          <ModalBody>
            <div className="text-center">{t(Keys.bookingErrorModalBody)}</div>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="primary" onClick={(): void => setBookingError(false)}>
              {t(Keys.bookingErrorModalButton)}
            </Button>
          </ModalFooter>
        </Modal>
        {isLoading && (
          <Overlay
            className="best-price__list-selections__spinner"
            positionFixed={true}
            text={t(Keys.onContinueLoadingText)}
            zIndex={1020}
            customSpinner={
              <div className="best-price-custom-spinner">
                <div />
                <div />
                <div />
                <div />
              </div>
            }
          />
        )}
      </div>
    </PrintTableWrapper>
  );
});

export default Ibe;
