import { useState, useEffect } from 'react';
import { i18n } from 'next-i18next';

const loadTranslations = (ns: string) => {
  let translationsObject = {};

  if (i18n && i18n.isInitialized) {
    translationsObject = i18n.getResourceBundle(i18n.language, ns);
  }
  return translationsObject;
};

function flattenObject<T>(obj: Record<string, any>, parentKey = ''): Record<string, T> {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(acc, flattenObject(obj[key], newKey));
    } else {
      acc[newKey] = obj[key];
    }
    return acc;
  }, {} as Record<string, T>);
}

// probably not needed for ysn, as we have different translations config
function useTranslationMGL(nameSpace: string) {
  const loadedTranslations = loadTranslations(nameSpace) as { [a: string]: string };

  // to prevent  https://nextjs.org/docs/messages/react-hydration-error
  const [translations, setTranslations] = useState<{ [a: string]: string }>({});

  useEffect(() => {
    let resp: { [a: string]: string } = {};
    if (loadedTranslations) {
      resp = flattenObject(loadedTranslations);
    }
    setTranslations({ ...resp });
  }, [loadedTranslations]);

  const getter = (a: string) => {
    return translations?.[a] || a;
  };

  return { t: getter };
}

export default useTranslationMGL;
