import { ApiBaseData, ApiTravelerType } from '@ibe/api';
import { FormConfig } from '@ibe/components';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { ObjectShape, OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';
import { AnyObject } from 'yup/lib/types';
import dayjs from 'dayjs';
import Keys from '@/Translations/generated/en/Checkout.json.keys';
import travellerFormConfig from '@/Config/travellerFormConfig';
import travellerFormConfigSmall from '@/Config/travellerFormConfigSmall';
import YsnConfigModel from '@/Config/YsnConfigModel';

export const getFormConfig = (
  isFirstItem: boolean,
  travelerType: ApiTravelerType,
  t: TFunction,
  initialValues?: { [key: string]: string },
  dateFormat?: string,
  isMobileView?: boolean
): FormConfig => {
  if (!isFirstItem) {
    return travellerFormConfigSmall(travelerType, t, undefined, isMobileView);
  } else {
    return travellerFormConfig(travelerType, t, undefined, dateFormat, isMobileView);
  }
};

export const getSalutations = (
  salutations: ApiBaseData[],
  travelerType: ApiTravelerType
): ApiBaseData[] => {
  switch (travelerType) {
    case ApiTravelerType.ADULT:
      return salutations.filter(
        salutation => salutation.code === 'MME' || salutation.code === 'MR'
      );
    case ApiTravelerType.CHILD:
      return salutations.filter(salutation => salutation.code === 'CHD');
    case ApiTravelerType.INFANT:
      return salutations.filter(salutation => salutation.code === 'INF');
    default:
      return salutations;
  }
};

export const getAge = (dateString: string): number => dayjs().diff(dayjs(dateString), 'years');

export const buildTravellerValidationSchema = (
  config: YsnConfigModel,
  t: TFunction,
  travelerType: ApiTravelerType
): OptionalObjectSchema<ObjectShape, AnyObject, TypeOfShape<ObjectShape>> => {
  const errorMessages = {
    adultCount: t(Keys.errorAdultCount),
    childrenCount: t(Keys.errorChildCount),
    childrenAge: t(Keys.errorChildrenAge),
    infantCount: t(Keys.errorInfantCount),
    required: t(Keys.errorRequiredField),
    invalidName: t(Keys.errorInvalidName),
    invalidChar: t(Keys.errorInvalidChar),
    invalidFirstMiddleName: t(Keys.errorInvalidFirstMiddleName),
    invalidChild: t(Keys.errorInvalidChild),
    invalidInfant: t(Keys.errorInvalidInfant),
    invalidAdult: t(Keys.errorInvalidAdult),
    invalidEmail: t(Keys.errorEmail),
    invalidPhone: t(Keys.errorPhone)
  };

  return yup.object().shape({
    firstname: yup
      .string()
      .max(56, errorMessages.invalidName)
      .test('firstNameAndMiddleNameMaxLength', errorMessages.invalidChar, function nameTooLong() {
        const firstName = this.parent.firstName ? this.parent.firstName.length : 0;
        const middleName = this.parent.middleName ? this.parent.middleName.length : 0;
        return firstName + middleName <= 56;
      }),
    lastname: yup.string().max(56, errorMessages.invalidName),
    gender: yup.string(),
    birthDate: yup.string().test('ageCheck', function ageCheck(value, testContext) {
      const { birthDate } = this.parent;
      const startDate = dayjs(testContext.options.context?.startDate);
      const birthDateDayJS = dayjs(`${birthDate}`);
      const isChild = travelerType === ApiTravelerType.CHILD;
      const isInfant =
        travelerType === ApiTravelerType.INFANT || travelerType === ApiTravelerType.INFANTWITHSEAT;
      let message = errorMessages.invalidAdult;
      let maxAgeToValidate: number | undefined = undefined;
      let minAgeToValidate: number | undefined = config.traveler.maxChildAge + 1;

      if (isChild) {
        message = errorMessages.invalidChild;
        maxAgeToValidate = config.traveler.maxChildAge;
        minAgeToValidate = config.traveler.maxInfantAge + 1;
      }
      if (isInfant) {
        maxAgeToValidate = config.traveler.maxInfantAge;
        minAgeToValidate = undefined;
        message = errorMessages.invalidInfant;
      }

      if (maxAgeToValidate) {
        const birthDateAddedMax = birthDateDayJS.add(maxAgeToValidate, 'year');
        if (!birthDateAddedMax.isAfter(startDate.add(1, 'day'), 'day')) {
          return this.createError({ message });
        }
      }
      if (minAgeToValidate) {
        const birthDateAddedMin = birthDateDayJS.add(minAgeToValidate, 'year');
        if (!birthDateAddedMin.isBefore(startDate.add(1, 'day'), 'day')) {
          return this.createError({ message });
        }
      }
      return true;
    })
  });
};
