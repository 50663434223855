import { ApiRoomContainer } from '@ibe/api';

export class ApiRoomContainerFactory {
  private static _roomContainer: ApiRoomContainer = {
    adults: 0,
    children: 0,
    infants: 0,
    infantsAges: [],
    childrenAges: []
  };

  static create = (
    partialRoomContainers: Array<Partial<ApiRoomContainer>>
  ): Array<ApiRoomContainer> => {
    const containers: Array<ApiRoomContainer> = [];
    partialRoomContainers.every(prc => containers.push({ ...this._roomContainer, ...prc }));
    return containers;
  };
}
