import { FormConfig } from '@ibe/components';
import { ApiTravelerType } from '@ibe/api';
import Keys from '@/Translations/generated/en/traveller-builder.json.keys';
import { TFunction } from 'i18next';

const randomNumber = (): number => Math.floor(Math.random() * 5);

export const getTravelerMockDataSmall = (): Record<string, unknown> => {
  return {
    salutation: 'MR',
    firstname: ['Firstname1', 'Firstname2', 'Firstname3', 'Firstname4', 'Firstname5'][
      randomNumber()
    ],
    lastname: ['Lastname1', 'Lastname2', 'Lastname3', 'Lastname4', 'Lastname5'][randomNumber()],
    birthDate: ['1960-06-26', '1970-07-27', '1980-08-28', '1990-09-29', '2000-10-30'][
      randomNumber()
    ]
  };
};
const travellerFormConfigSmall = (
  travelerType: ApiTravelerType,
  t: TFunction,
  initialValues?: { [key: string]: string },
  isMobileView?: boolean
): FormConfig => {
  return {
    rows: [
      {
        key: 'row1',
        elements: [
          {
            tag: 'select',
            key: 'salutation',
            placeholder: t(Keys.salutation),
            externalDataOptions: 'salutations',
            rowGrid: {
              md: 6
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            }
          },
          {
            tag: 'input',
            key: 'firstname',
            placeholder: t(Keys.firstname),
            initialValue: initialValues?.firstname || '',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              md: 6
            }
          },
          {
            tag: 'input',
            key: 'lastname',
            placeholder: t(Keys.lastname),
            initialValue: initialValues?.lastname || '',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              md: 6
            }
          },
          {
            tag: 'datePicker',
            key: 'birthDate',
            placeholder: t(Keys.dateOfBirth),
            withKeyboardSupport: true,
            showInModal: isMobileView,
            modalLabel: t(Keys.modalLabel),
            rowGrid: {
              md: 6
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                },
                ...(travelerType === ApiTravelerType.ADULT
                  ? [
                      {
                        type: 'isOlderThanAgeOnDateString',
                        customMessage: t(Keys.errorInvalidAdult)
                      }
                    ]
                  : travelerType === ApiTravelerType.CHILD
                  ? [
                      {
                        type: 'isYoungerThanAgeOnDateString',
                        customMessage: t(Keys.errorInvalidAdult)
                      },
                      {
                        type: 'isOlderThanInfantOnDateString',
                        customMessage: t(Keys.errorInvalidChild)
                      }
                    ]
                  : [{ type: 'isInfantOnDateString', customMessage: t(Keys.errorInvalidInfant) }])
              ]
            }
          }
        ]
      }
    ]
  };
};

export default travellerFormConfigSmall;
