import { useCallback, useMemo, useState } from 'react';
import {
  SessionData,
  useAppService,
  useAuth,
  useBookingService,
  useConfig,
  usePersistSearchParams
} from '@ibe/components';
import { createUniqueId, SessionPersistence } from '@ibe/services';
import { setDefaultLocale } from 'react-datepicker';
import { useRouter } from 'next/router';
import { languageSelection } from '@/Translations';
import { HeaderProps } from './Header';
import useTranslationMGL from '@/Util/useTranslationMgl';
import dayjs from 'dayjs';
import { getLocale } from '@/Config/config';
import PageUrl from '@/Models/PageUrl';

const useHeaderHooks = (props: HeaderProps) => {
  const { links } = props;
  const router = useRouter();
  const appService = useAppService();
  const bs = useBookingService();
  const auth = useAuth();
  const config = useConfig();
  const { clearSearchParams } = usePersistSearchParams();
  const [showDesktop, setShowDesktop] = useState<boolean>(true);
  const { t } = useTranslationMGL('Header');
  const linksKeys = useMemo(() => links.map((_, numb) => `${numb}_${createUniqueId()}`), []);

  const languageOptions = useMemo(() => {
    return languageSelection.map(lang => {
      return {
        label: t(lang),
        value: lang
      };
    });
  }, [t]);

  const handleLanguageChange = useCallback(
    async (lang: string): Promise<void> => {
      appService.setLang(lang);
      //TODO!! get booking like in CheckoutStore
      if (bs.booking && bs.booking.bookedItems.length > 0) {
        await bs.doAttemptBooking();
      }
      router.push(
        {
          pathname: router.pathname,
          query: router.query
        },
        router.asPath,
        { locale: appService.lang }
      );
      dayjs.locale(getLocale(lang));
      setDefaultLocale(appService.lang);
    },
    [appService, bs, router]
  );

  const logout = async () => {
    auth.removeToken();
    clearSearchParams();
    auth.authService.invalidate();
    bs.clearBooking();
    const session = new SessionPersistence<SessionData>(SessionData);
    session.useKey(config.sessionKeyCart);
    session.clear();
    await router.push(PageUrl.HOME);
  };

  return {
    linksKeys,
    languageOptions,
    showDesktop,
    setShowDesktop,
    auth,
    logout,
    handleLanguageChange,
    config
  };
};

export default useHeaderHooks;
