import React, { useEffect, useRef } from 'react';
import Keys from '@/Translations/generated/en/Checkout.json.keys';
import { Button } from 'reactstrap';
import useTranslationMGL from '@/Util/useTranslationMgl';
import { useWorkflowService } from '@/Util/contexts';

interface BackButtonProps {
  color?: string;
  className?: string;
  onClick?: () => void;
}

const BackButton = (props: BackButtonProps): JSX.Element => {
  const { t } = useTranslationMGL('Checkout');
  const { color = 'secondary', className, onClick } = props;
  const { workflow, setNextStepSlug } = useWorkflowService();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const prev = (): void => {
    timeoutRef.current = setTimeout(() => {
      setNextStepSlug(workflow.prevStepSlug || '');
    }, 300);
  };

  useEffect(() => {
    return () => {
      if (!!timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Button className={`navButton ${className}`} color={color} onClick={onClick || prev}>
      {t(Keys.prev)}
    </Button>
  );
};

export default BackButton;
