import React, { useMemo, ReactNode, useEffect, useState } from 'react';
import {
  BookingSearchModule,
  useClientPersistSearchParams,
  DateDisplay,
  useAuth
} from '@ibe/components';
import { useRouter } from 'next/router';
import useQuery from '@/Util/useQuery';
import isClient from '@/Util/globals';
import { BookingParamsTransformer } from '@/Params/BookingParamsTransformer';
import { ApiBookingPane, ApiBookingSearch } from '@ibe/api';
import useTranslationMGL from '@/Util/useTranslationMgl';
import Keys from '@/Translations/generated/en/booking-list.json.keys';

export const SS_BOOKING_SEARCH_PARAMS_KEY = 'bookingSearchParams';

interface Props {
  bookingDetailsLink?: string;
}
const BookingList = ({ bookingDetailsLink }: Props) => {
  const { t } = useTranslationMGL('booking-list');

  const query = useQuery();
  const router = useRouter();
  const auth = useAuth();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const { getSearchParams, setSearchParams } =
    useClientPersistSearchParams<Partial<ApiBookingSearch>>(isClient() ? window || null : null) ||
    {};

  useEffect(() => {
    setLoggedIn(auth.authState.isLoggedIn);
  }, [auth.authService.isLoggedIn]);

  const updateUrlParams = (params: Partial<ApiBookingSearch>): void => {
    // set ss item, so when returning to list from BookingDetails, the same list is shown
    sessionStorage.setItem(SS_BOOKING_SEARCH_PARAMS_KEY, JSON.stringify(params));

    setSearchParams(
      router.asPath.split('?')[0],
      (({
        bookingDateStart,
        bookingDateEnd,
        travelDateStart,
        travelDateEnd
      }): Partial<ApiBookingSearch> => ({
        bookingDateStart,
        bookingDateEnd,
        travelDateStart,
        travelDateEnd
      }))(params)
    );
    const urlParams = BookingParamsTransformer.encode(params);
    router.push(`${router.asPath.split('?')[0]}?${urlParams}${urlParams && '&'}`);
  };

  const searchParams = useMemo(() => {
    const params = !!getSearchParams ? getSearchParams(router.asPath.split('?')[0]) : undefined;
    return Object.keys(Object.fromEntries(query.entries())).length > 0
      ? {
          ...BookingParamsTransformer.decode(Object.fromEntries(query.entries())),
          ...params
        }
      : { ...params };
  }, [getSearchParams, query]);

  function generateBookingDetailsLink(bookingId: string | undefined): string {
    if (bookingDetailsLink && bookingId) {
      return `${bookingDetailsLink}?${`bookingId=${bookingId}`}`;
    }
    return '';
  }

  function getAgentNameInfo(bookingPane: ApiBookingPane): string {
    if (bookingPane.agent?.lastName && bookingPane.agent?.firstName) {
      return `${bookingPane.agent?.lastName} ${bookingPane.agent?.firstName}`;
    }
    return '-/-';
  }

  function redirectToDetailsPage(bookingId: string | undefined) {
    router.push(generateBookingDetailsLink(bookingId));
  }

  const handleBookingPaneClick = (bookingId: string | undefined): void => {
    if (bookingId) {
      router.push(`${bookingDetailsLink}?bookingId=${bookingId}`);
    }
  };

  function renderCustomBookingRow(bookingPane: ApiBookingPane): ReactNode {
    return (
      <tbody key={bookingPane.bookingNumber}>
        <tr
          className="iso__bookingList__row"
          onClick={(): void => redirectToDetailsPage(bookingPane.bookingId)}
        >
          <td>{bookingPane.bookingNumber}</td>
          <td>{t(`${bookingPane.bookingState}`)}</td>
          <td>
            <DateDisplay date={bookingPane.bookingDate as string} />
          </td>
          <td>
            <DateDisplay date={bookingPane.travelStartDate as string} />
          </td>
          <td>
            {bookingPane.price?.finalPrice} {bookingPane.price?.currencyCode}
          </td>
          <td>{getAgentNameInfo(bookingPane)}</td>
          <td>{bookingPane.leadTravelerName}</td>
        </tr>
      </tbody>
    );
  }

  return (
    <>
      {isLoggedIn && (
        <BookingSearchModule
          onSearch={updateUrlParams}
          searchParams={searchParams}
          handleBookingPaneClick={handleBookingPaneClick}
          customBookingRecordRenderer={renderCustomBookingRow}
          showLeadTravelName
        />
      )}
    </>
  );
};

export default BookingList;
