import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import styles from './BreadCrumb.module.scss';
import Link from 'next/link';
import { faHome, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';

const BreadCrumb = () => {
  const mgnlCtx = useGlobalMGLProps();
  const { breadCrumb: breadCrumbNodes = [], rootNodePath } = mgnlCtx || {};
  if (!breadCrumbNodes.length) return <></>;
  return (
    <div className={`iso__breadCrumb ${styles.iso__breadCrumb}`}>
      {breadCrumbNodes?.map(node => {
        if (node.path === rootNodePath) {
          return (
            <Link key={node.path} href={rootNodePath}>
              <span className="mr-2">
                <FontAwesomeIcon icon={faHome} />
              </span>
            </Link>
          );
        }
        return (
          <Fragment key={node.path}>
            <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
            <Link href={node.path}>
              <span className="mr-2"> {node.title} </span>
            </Link>
          </Fragment>
        );
      })}
    </div>
  );
};

export default BreadCrumb;
