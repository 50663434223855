import React, { useState } from 'react';
import styles from '@/components/alertBox/AlertBox.module.scss';
import { Button, Card, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import sanitize from '@/Util/sanitize';

interface Props {
  header: string;
  richText?: string;
}

const AlertBox = ({ header, richText }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={styles.alertBox}>
      <Button className={styles.alertBox__button} onClick={(): void => setIsOpen(value => !value)}>
        <span>{header}</span>
        <FontAwesomeIcon
          className={`${styles.alertBox__chevron} ${isOpen ? styles.alertBox__chevronUp : ''}`}
          icon={faChevronDown}
        />
      </Button>
      <Collapse isOpen={isOpen}>
        <Card className={styles.alertBox__card}>
          {richText && (
            <div className="" dangerouslySetInnerHTML={{ __html: sanitize(richText, true) }} />
          )}
        </Card>
      </Collapse>
    </div>
  );
};

export default AlertBox;
