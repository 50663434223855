import dayjs from 'dayjs';
import YsnConfigModel from '@/Config/YsnConfigModel';

class DateFormatter {
  private _config: YsnConfigModel;

  constructor() {}

  set config(value: YsnConfigModel) {
    this._config = value;
  }

  toModel(date: string): string {
    return dayjs(date).format(this._config.formatDate);
  }
}

export default DateFormatter;
