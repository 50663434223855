import DOMPurify from 'isomorphic-dompurify';

function sanitize(innerHtml: string, withTargetAttr?: boolean): string {
  if (withTargetAttr) addTargetBlankAttrToATagSecurely();
  return DOMPurify.sanitize(innerHtml);
}

const addTargetBlankAttrToATagSecurely = () => {
  // For temporarily saving original target value
  const TEMP_TARGET_ATTRIBUTE = 'data-target-temp';

  /**
   * Add DOMPurify hook to handling exceptional rules for certain HTML attributes.
   */

  DOMPurify.addHook('beforeSanitizeAttributes', function (node) {
    // Preserve default target attribute value
    if (node.tagName === 'A') {
      const targetValue = node.getAttribute('target');
      if (targetValue) {
        node.setAttribute(TEMP_TARGET_ATTRIBUTE, targetValue);
      } else {
        // set default value
        node.setAttribute('target', '_self');
      }
    }
  });

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    if (node.tagName === 'A' && node.hasAttribute(TEMP_TARGET_ATTRIBUTE)) {
      const value = node.getAttribute(TEMP_TARGET_ATTRIBUTE);
      if (value) node.setAttribute('target', value);
      node.removeAttribute(TEMP_TARGET_ATTRIBUTE);

      // Additionally set `rel="noopener"` to prevent another security issue.
      if (node.getAttribute('target') === '_blank') {
        node.setAttribute('rel', 'noopener');
      }
    }
  });
};

export default sanitize;
