import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ApiPackageModel, ApiPrice } from '@ibe/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Price } from '@ibe/components';
import { MagnoliaDamNode } from '@/Util/magnolia';
import useTranslationMGL from '@/Util/useTranslationMgl';
import Keys from '@/Translations/generated/en/destinations.json.keys';

export interface DestinationRowProps {
  destinationImage?: MagnoliaDamNode;
  name?: string;
  price?: ApiPrice;
  destinations?: DestinationDetailsProps[];
  onClick?: (codes: string[]) => void;
}

export interface DestinationDetailsProps {
  code: string;
  name?: string;
  packages: ApiPackageModel[];
  price?: ApiPrice;
}

const DestinationRow = (props: DestinationRowProps) => {
  const { name, destinations, price, destinationImage, onClick } = props;

  const { t } = useTranslationMGL('destinations');
  const [isExpanded, setExpanded] = useState<boolean>(false);

  function getDestinationsLabel(): string {
    if (!destinations || destinations.length == 0) {
      return `0 ${t(Keys.destination_plural)}`;
    }
    if (destinations.length == 1) {
      return `${destinations.length} ${t(Keys.destination)}`;
    }
    return `${destinations.length} ${t(Keys.destination_plural)}`;
  }

  function getHotelsLabel(packages: ApiPackageModel[]) {
    if (!packages || packages.length == 0) {
      return `0 ${t(Keys.hotel_plural)}`;
    }
    if (packages.length == 1) {
      return `${packages.length} ${t(Keys.hotel)}`;
    }
    return `${packages.length} ${t(Keys.hotel_plural)}`;
  }

  function getDetailsInfoRow() {
    const allPackages = destinations?.flatMap(destination => destination.packages) || [];
    const allDestinationsCodes = destinations?.flatMap(destination => destination.code) || [];
    return (
      <Row
        className="iso_destinations__destination_row-details-row info no-gutters"
        onClick={() => {
          onClick && onClick(allDestinationsCodes);
        }}
      >
        <Col>
          <Row>
            <Col xs={12} md={6} className="align-self-center">
              <div className="font-weight-bold iso_destinations__destination_row-name">
                {t(Keys.allDestinations)}
              </div>
            </Col>
            <Col xs={12} md={6} className="align-self-center">
              <div className="">{getHotelsLabel(allPackages)}</div>
            </Col>
          </Row>
        </Col>
        <Col
          xs={6}
          md={3}
          className="col-3 align-self-center d-flex justify-content-end iso_destinations__destination_row-details-row-price"
        >
          <Price price={price} prefix={t(Keys.from)} />
        </Col>
      </Row>
    );
  }

  return (
    <Row
      onClick={() => {
        setExpanded(!isExpanded);
      }}
      className={`${
        isExpanded
          ? 'iso_destinations__destination_row-active'
          : 'iso_destinations__destination_row'
      } p-2`}
    >
      <Col>
        <div className="position-relative">
          <div className="iso_destinations__destination_row-image_container">
            {isExpanded && destinationImage && (
              <img
                src={destinationImage['@link']}
                className="iso_destinations__destination_row-image"
              />
            )}
          </div>
          <div className="iso_destinations__destination_row-image_info">
            <Row className="pb-2">
              <Col>
                <Row>
                  <Col xs={12} md={6} className="align-self-center">
                    <div className="font-weight-bold iso_destinations__destination_row-name">
                      {name}
                    </div>
                  </Col>
                  <Col xs={12} md={6} className="align-self-center">
                    {!isExpanded && <div>{getDestinationsLabel()}</div>}
                  </Col>
                </Row>
              </Col>
              <Col
                xs={6}
                md={3}
                className=" align-self-center d-flex justify-content-end iso_destinations__destination_row-price"
              >
                {!isExpanded && <Price price={price} prefix={t(Keys.from)} />}
                <span className="expand_icon justify-content-end align-self-center">
                  <FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown} />
                </span>
              </Col>
            </Row>
          </div>
        </div>
        <div className="iso_destinations__destination_row-details">
          {destinations && destinations.length > 1 && getDetailsInfoRow()}
          {destinations?.map(destination => (
            <Row
              className="iso_destinations__destination_row-details-row no-gutters"
              key={`destination-${destination.name}`}
              onClick={() => {
                onClick && onClick([destination.code]);
              }}
            >
              <Col>
                <Row>
                  <Col xs={12} md={6} className="align-self-center">
                    <div className="font-weight-bold iso_destinations__destination_row-name">
                      {destination.name}
                    </div>
                  </Col>
                  <Col xs={12} md={6} className="align-self-center">
                    <div className="">{getHotelsLabel(destination.packages)}</div>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={6}
                md={3}
                className="col-3 align-self-center d-flex justify-content-end iso_destinations__destination_row-details-row-price"
              >
                {destination.price && <Price price={destination.price} prefix={t(Keys.from)} />}
              </Col>
            </Row>
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default DestinationRow;
