import { ApiDurationInfo, ApiDurationType } from '@ibe/api';

export class ApiDurationFactory {
  private static _duration: ApiDurationInfo = {
    duration: 0,
    details: undefined,
    type: ApiDurationType.NIGHT
  };

  static create = (partialDuration: Partial<ApiDurationInfo>) => {
    return {
      ...this._duration,
      ...partialDuration
    };
  };
}
