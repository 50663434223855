import Traveler from '../components/checkout/Traveler';
import Extras from '../components/checkout/Extras';
import Confirmation from '../components/checkout/Confirmation';
import { CheckoutStep } from '@/Models/CheckoutStep';
import StepId from '../components/checkout/StepId';

const workflowCheckout: CheckoutStep[] = [
  {
    id: StepId.EXTRAS,
    slug: 'extras',
    title: '',
    label: 'Extra',
    component: Extras,
    steps: null
  },
  {
    id: StepId.TRAVELER_DETAILS,
    slug: 'traveler',
    title: '',
    label: 'Traveler',
    component: Traveler,
    steps: null
  },
  {
    id: StepId.CONFIRMATION,
    slug: 'confirmation',
    title: '',
    label: 'Confirmation',
    component: Confirmation,
    steps: null
  }
];
export default workflowCheckout;
