import {
  ApiErrorResponseFromJSON,
  ApiGetPackagesRequest,
  ApiGetPackagesResponse,
  ApiItemType
} from '@ibe/api';
import { ApiService } from '@ibe/services';

class PackageService {
  private _api: ApiService;

  constructor() {}

  set api(api: ApiService) {
    this._api = api;
  }

  private _searchBestPricePackagesDefaults: ApiGetPackagesRequest = {
    subType: ApiItemType.PREDEFINEDPACKAGE,
    calculatePrices: true,
    classCode: '',
    destination: '',
    endDate: '',
    startDate: ''
  };

  async searchBestPricePackages(
    rq: Partial<ApiGetPackagesRequest>
  ): Promise<ApiGetPackagesResponse> {
    try {
      return this._api.searchBestPricePackages({
        ...this._searchBestPricePackagesDefaults,
        ...rq
      });
    } catch (e) {
      throw ApiErrorResponseFromJSON(e);
    }
  }
}

export default PackageService;
