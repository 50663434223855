import LangSwitchDrop from './LangSwitchDropdown';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { Navbar } from 'reactstrap';
import PageUrl from '../../Models/PageUrl';
import useHeaderHooks from './useHeaderHook';
import { HeaderProps } from './Header';
import LoginButton from './LoginButton';
import LoginModal from '@/Layouts/Header/LoginModal';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import ImageWrapper from '@/components/ImageWrapper';
import { useMount } from 'react-use';
import { Persistence, SessionPersistence } from '@ibe/services';

const HeaderDesktop = (props: HeaderProps) => {
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const openLoginModal = () => {
    setLoginModalOpen(true);
  };

  return (
    <Navbar className="iso__header iso__header--desktop d-none d-lg-block">
      <HeaderTop {...props} openLoginModal={openLoginModal} />
      <HeaderDown {...props} />
      <LoginModal
        isOpen={loginModalOpen}
        toggle={() => setLoginModalOpen(p => !p)}
        onSuccess={() => setLoginModalOpen(false)}
      />
    </Navbar>
  );
};

export default HeaderDesktop;

class TokenStore {
  token?: string;
}

export const HeaderTop = (props: HeaderProps & { openLoginModal: () => void }) => {
  const { handleLanguageChange, languageOptions, auth, logout, config } = useHeaderHooks(props);
  const { hasCheckoutComponent } = useGlobalMGLProps() || {};
  const [isLoggedIn, setLoggedIn] = useState(auth.authState.isLoggedIn);
  const authSession: Persistence<TokenStore> = new SessionPersistence<TokenStore>(TokenStore);
  const storageListener = (e: StorageEvent) => {
    if (config.sessionKeyTabsShared) {
      if (e.key === config.sessionKeyAuth) {
        if (e.oldValue === null && e.newValue) {
          setLoggedIn(true);
          authSession.useKey(config.sessionKeyAuth);
          const data = authSession.get();
          if (data.token) {
            auth.authService.fromJwt(data.token);
          }
        } else if (e.newValue === null && e.oldValue) {
          setLoggedIn(false);
        }
      }
    }
  };

  useEffect(() => {
    setLoggedIn(auth.authState.isLoggedIn);
  }, [auth.authState.isLoggedIn]);

  useMount(() => {
    window?.addEventListener('storage', storageListener);
    return () => {
      window?.removeEventListener('storage', storageListener);
    };
  });

  return (
    <div className="header-top d-flex justify-content-end ">
      {!hasCheckoutComponent && (
        <LangSwitchDrop
          handleLanguageChange={handleLanguageChange}
          languageOptions={languageOptions}
        />
      )}
      <LoginButton
        logout={() => {
          logout().catch(() => console.debug('err logout'));
        }}
        {...props}
        openLoginModal={props.openLoginModal}
        isLoggedIn={isLoggedIn}
        firstname={auth.authState.firstName || undefined}
        lastname={auth.authState.lastName || undefined}
      />
    </div>
  );
};

const HeaderDown = (props: HeaderProps) => {
  const { linksKeys, auth } = useHeaderHooks(props);
  const { links, image, proImage } = props;

  return (
    <div className="header-down">
      <div className="image-container">
        <Link href={PageUrl.HOME}>
          {auth.authState.isLoggedIn && proImage && proImage['@link'] ? (
            <ImageWrapper
              className="image"
              image={proImage}
              alt={proImage?.metadata?.caption || 'Logo'}
              width={100}
              height={100}
            />
          ) : (
            <ImageWrapper
              className="image"
              image={image}
              alt={image?.metadata?.caption || 'Logo'}
              width={100}
              height={100}
            />
          )}
        </Link>
      </div>
      <div className="navbar-link-container--desktop">
        {links.map((link, index) => (
          <Link key={linksKeys[index]} {...link.linkProps}>
            <span>{link.label}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};
