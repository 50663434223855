import sanitize from '@/Util/sanitize';
import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'reactstrap';

interface Props {
  showInitial: number;
  moreLabel: string;
  lessLabel: string;
  content: string;
}

const ListDescription: React.FC<Props> = ({ content, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const isOverflowing = contentRef.current.scrollHeight > contentRef.current.clientHeight;
      setShowButton(isOverflowing);
    }
  }, [content]);

  return (
    <div>
      <div
        ref={contentRef}
        className={`richText ${isExpanded ? '' : 'collapsed-box '}`}
        dangerouslySetInnerHTML={{ __html: sanitize(content, true) }}
      ></div>
      {showButton ? (
        <Button
          onClick={e => {
            e.stopPropagation();
            setIsExpanded(p => !p);
          }}
          className="listShowMore__button"
          color="link"
        >
          {!isExpanded ? props.moreLabel : props.lessLabel}
        </Button>
      ) : null}
    </div>
  );
};

export default ListDescription;
