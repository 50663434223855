import React from 'react';
import { observer } from 'mobx-react';
import { FooterBuilder, FooterRow } from '@ibe/components';
import Link from 'next/link';
import {
  MagnoliaPageNode,
  MagnoliaComponentNode,
  MagnoliaDamNode,
  LinkSwitchableMixin
} from '@/Util/magnolia';
import sanitize from '@/Util/sanitize';
import PageUrl from '@/Models/PageUrl';
import { getNextLinkAttrs } from '@/components/linkList/Link';
import ImageWrapper from '@/components/ImageWrapper';

interface MagnoliaPageNodeFooter extends Omit<MagnoliaPageNode, '@nodes'> {
  '@nodes': string[];
}

interface MagnoliaComponentNodeFooter extends Omit<MagnoliaComponentNode, '@nodes'> {
  '@nodes': string[];
}

interface LinksTypeChild extends MagnoliaComponentNodeFooter {
  title: string;
}
type LinksType = { [a: string]: LinksTypeChild & LinkSwitchableMixin };
interface NavSectionTypeChild extends MagnoliaComponentNodeFooter {
  title?: string;
  links?: LinksType & { '@nodes': string[]; title: string };
}

interface NavSectionType extends MagnoliaComponentNodeFooter {
  title?: string;
}

interface ImageType extends MagnoliaComponentNodeFooter {
  imageLink: MagnoliaDamNode;
  imageAltText?: string;
}

interface FooterPropsCMS extends MagnoliaPageNodeFooter {
  image?: ImageType;
  description?: string;
  navSections?: { [a: string]: NavSectionTypeChild } & NavSectionType;
  corporateInformation?: string;
  copyright?: string;
}

const FooterBuild = observer((props: FooterPropsCMS) => {
  const optionsSample = {
    className: 'footer-options'
  };

  const headerSample: FooterRow['cols'][0]['header'] = {
    className: 'footer-header',
    tag: 'h3',
    value: ''
  };

  const { image, description, navSections, corporateInformation } = props;

  const getSub = (a: NavSectionTypeChild) => {
    return {
      rowGrid: { md: '4' },
      header: headerSample ? { ...headerSample, value: a.title } : { value: a.title },
      options:
        a && a['links'] && a['@nodes']
          ? a['links']['@nodes'].map(abt => {
              const val = a?.['links']?.[abt];
              return {
                ...optionsSample,
                customRenderer: (a: any) => {
                  if (!val) return <></>;
                  const linkAttrs = getNextLinkAttrs(val);
                  return (
                    <Link {...linkAttrs} locale={linkAttrs.download === true ? false : undefined}>
                      <div>{val?.title || ''}</div>
                    </Link>
                  );
                }
              };
            })
          : []
    };
  };
  const navSubCols: any =
    navSections &&
    navSections['@nodes'] &&
    navSections['@nodes'].map(node => {
      return getSub(navSections[node]);
    });

  return (
    <FooterBuilder
      fluidContainer={true}
      rows={[
        {
          cols: [
            {
              rowGrid: { md: '12', lg: 9 },
              subCols: navSubCols ? navSubCols : []
            },
            {
              rowGrid: { md: 12, lg: 3, className: 'footer-image-container' },
              customContent: (
                <div>
                  {image?.imageLink?.['@link'] && (
                    <Link href={PageUrl.HOME}>
                      <ImageWrapper
                        className="footerlogo"
                        src={image?.imageLink?.['@link']}
                        alt={image?.imageAltText || ''}
                        width={100}
                        height={100}
                      />
                    </Link>
                  )}
                  {description ? (
                    <div
                      className="footer-description"
                      dangerouslySetInnerHTML={{ __html: sanitize(description, true) }}
                    />
                  ) : null}
                </div>
              )
            }
          ]
        },
        {
          cols: [
            {
              rowGrid: { lg: 12, className: 'footer-corporateInformation-wrapper' },
              customContent: (
                <>
                  {corporateInformation && (
                    <div
                      className="footer-corporateInformation"
                      dangerouslySetInnerHTML={{ __html: sanitize(corporateInformation, true) }}
                    />
                  )}
                </>
              )
            }
          ]
        }
      ]}
    />
  );
});

const Footer = (props: FooterPropsCMS) => {
  return (
    <div className="iso__footer__wrapper hide-in-print">
      <div className="max-width">
        <div className="horizontal-spread ml-2 mr-2 mr-sm-5 ml-sm-5">
          <FooterBuild {...props} />
        </div>
        {props.copyright && (
          <div
            className="footer-copyright ml-2 mr-2 mr-sm-5 ml-sm-5"
            dangerouslySetInnerHTML={{ __html: sanitize(props.copyright, true) }}
          />
        )}
      </div>
    </div>
  );
};

export default Footer;
