import React, { ReactNode } from 'react';
import { ApiComponent, ApiComponentType, ApiItemType, ApiTransfer } from '@ibe/api';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBed,
  faBus as faBusSolid,
  faPlane as faPlaneSolid,
  faPlus
} from '@fortawesome/free-solid-svg-icons';

const defaultIconsConfiguration = new Map<ApiItemType, ReactNode>([
  [
    ApiItemType.HOTEL,
    <FontAwesomeIcon
      key="includedServicesPreview_default_hotel_icon"
      className="mx-1"
      icon={faBed}
    />
  ],
  [
    ApiItemType.FLIGHT,
    <FontAwesomeIcon
      key="includedServicesPreview_default_transport_icon"
      className="ml-1"
      icon={faPlaneSolid}
    />
  ],
  [
    ApiItemType.TRANSFER,
    <FontAwesomeIcon
      key="includedServicesPreview_default_transfer_icon"
      className="mr-1"
      icon={faBusSolid}
    />
  ]
]);

export interface CartIncludedServicesPreviewProps {
  components: ApiComponent[];
  iconsConfiguration?: Map<ApiItemType, ReactNode>;
}

export function hasCartAccommodation(components: ApiComponent[]): boolean {
  return hasComponentOfGivenItemType(ApiItemType.HOTEL, components, ApiComponentType.MAIN);
}
export function hasCartTransportation(components: ApiComponent[]): boolean {
  return hasComponentOfGivenItemType(ApiItemType.FLIGHT, components, ApiComponentType.REQUIRED);
}
export function hasCartIncludedTransfer(components: ApiComponent[]): boolean {
  return hasIncludedTransfer(components);
}

export function hasCartIncludedInsurance(components: ApiComponent[]): boolean {
  return hasComponentOfGivenItemType(ApiItemType.INSURANCE, components, ApiComponentType.REQUIRED);
}

export function hasCartOptionalInsurance(components: ApiComponent[]): boolean {
  return hasComponentOfGivenItemType(ApiItemType.INSURANCE, components, ApiComponentType.OPTIONAL);
}

function hasIncludedTransfer(components: ApiComponent[]): boolean {
  return components.some(
    component =>
      component.itemType === ApiItemType.TRANSFER &&
      component.selectedItems &&
      component.selectedItems.some(service => {
        const transfer = service as ApiTransfer;
        return transfer.defaultService;
      })
  );
}

function hasComponentOfGivenItemType(
  itemType: ApiItemType,
  components: ApiComponent[],
  componentType: ApiComponentType
) {
  return components.some(
    component => component.itemType === itemType && component.componentType === componentType
  );
}

const CartIncludedServicesPreview = (props: CartIncludedServicesPreviewProps): JSX.Element => {
  const { components, iconsConfiguration } = props;
  const icons = iconsConfiguration || defaultIconsConfiguration;

  return (
    <Row className="iso__includedServicesPreview__container justify-content-end">
      {hasCartIncludedTransfer(components) && (
        <Col className="col-auto ">
          <Row>{icons.get(ApiItemType.TRANSFER)} </Row>
        </Col>
      )}
      {hasCartAccommodation(components) && (
        <Col className="col-auto">
          <Row>
            <FontAwesomeIcon icon={faPlus} /> {icons.get(ApiItemType.HOTEL)}
          </Row>
        </Col>
      )}
      {hasCartTransportation(components) && (
        <Col className="col-auto">
          <Row>
            <FontAwesomeIcon icon={faPlus} /> {icons.get(ApiItemType.FLIGHT)}
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default CartIncludedServicesPreview;
