import DateFormatterContext from '../Contexts/DateFormatterlContext';
import { useContext } from 'react';
import { useLocalStore } from 'mobx-react';
import DateFormatter from '@/Util/DateFormatter';
import { useConfig } from '@ibe/components';

function useDateFormatter() {
  const context = useContext(DateFormatterContext);
  const formatter: DateFormatter = useLocalStore<DateFormatter>(() => context);

  formatter.config = useConfig();

  return formatter;
}

export default useDateFormatter;
