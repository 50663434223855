import { PackageParams } from '@ibe/components';

export enum QueryParamKey {
  BOOKING_ID = 'bookingId'
}

export abstract class QueryUtils {
  static getBookingId(query: URLSearchParams) {
    return query.get(QueryParamKey.BOOKING_ID);
  }

  static areEqual(a?: Partial<PackageParams>, b?: Partial<PackageParams>) {
    return JSON.stringify(a) === JSON.stringify(b);
  }
}
