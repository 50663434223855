import CartPriceDetails from '@/components/PackageDetails/CartPriceDetails';
import Keys from '@/Translations/generated/en/Checkout.json.keys';
import { Price } from '@ibe/components';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useMemo, useState } from 'react';
import CheckoutStore from '@/components/checkout/CheckoutStore';
import useTranslationMGL from '@/Util/useTranslationMgl';
import { useWorkflowService } from '@/Util/contexts';
import StepId from '@/components/checkout/StepId';
import { ApiPrice, ApiPriceModifierType } from '@ibe/api';

const CheckoutPriceInfo = (props: {
  store: CheckoutStore;
  next: () => Promise<void>;
  backButton: JSX.Element;
}): React.JSX.Element => {
  const { t } = useTranslationMGL('Checkout');

  const { store, next, backButton } = props;

  const { workflow } = useWorkflowService();
  const [bookingTotalPrice, setBookingTotalPrice] = useState<ApiPrice | undefined>();
  const [priceDetailsModalOpen, setPriceDetailsModalOpen] = useState<boolean>(false);
  const togglePriceDetailsModalOpen = (): void => {
    setPriceDetailsModalOpen(value => !value);
  };

  const handleButtonClick = async (): Promise<void> => {
    await next();
  };

  const discountsValue = useMemo(() => {
    if (store.booking) {
      return store.booking.price.modifiers
        .filter(m => m.type === ApiPriceModifierType.DISCOUNT)
        .map(value => value.absolute)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    }
    return 0;
  }, [store.booking]);

  useEffect(() => {
    const bookingPrice = { ...store.booking?.price } as ApiPrice;

    if (bookingPrice?.finalPrice) {
      bookingPrice.finalPrice -= discountsValue;
    }
    setBookingTotalPrice(bookingPrice);
  }, [discountsValue, store.booking?.price]);

  return store.packageCart && store.packageCart.packageModel.packageDetails ? (
    <>
      <div className="checkout__container__price">
        <CartPriceDetails checkoutStore={store} />
      </div>

      <div className="bestprice-price-details--mobile hide-in-print">
        <div className="bestprice-price-details">
          <div className="bestprice-price-details__total">
            <Button color="link" onClick={(): void => setPriceDetailsModalOpen(true)}>
              {t(Keys.details)}
            </Button>
            <div>{t(Keys.summaryTotalPrice)}</div>
            <div className="bestprice-price-details__total__price">
              <Price price={bookingTotalPrice} />
            </div>
          </div>
        </div>
        <Row className="mt-2">
          <Col xs={12} sm={6}>
            {backButton}
          </Col>
          <Col xs={12} sm={6}>
            <Button color="primary" className="w-100" onClick={handleButtonClick}>
              {workflow.currentStep?.id === StepId.TRAVELER_DETAILS ? t(Keys.order) : t(Keys.next)}
            </Button>
          </Col>
        </Row>
        <Modal
          className="bestprice-price-details__modal__container m-0 h-100"
          isOpen={priceDetailsModalOpen}
          toggle={togglePriceDetailsModalOpen}
          container={document.getElementById('iso') || undefined}
        >
          <ModalBody className="bestprice-price-details__modal__checkout checkout__container">
            <FontAwesomeIcon
              icon={faTimes}
              className="best-price-package-details__modal__close"
              onClick={(): void => setPriceDetailsModalOpen(false)}
            />
            <CartPriceDetails checkoutStore={store} />
          </ModalBody>
        </Modal>
      </div>
    </>
  ) : (
    <></>
  );
};

export default CheckoutPriceInfo;
