import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import React, { useState } from 'react';
import { Navbar } from 'reactstrap';
import { HeaderProps } from './Header';
import useHeaderHooks from './useHeaderHook';
import { HeaderTop } from './Desktop';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import PageUrl from '@/Models/PageUrl';
import { AlertWrapper, useAuth } from '@ibe/components';
import LoginModal from '@/Layouts/Header/LoginModal';
import ImageWrapper from '@/components/ImageWrapper';

const HeaderMobile = (props: HeaderProps) => {
  const [open, setOpen] = useState(false);

  return (
    <AlertWrapper
      alertContainerProps={{ className: 'd-block d-lg-none w-100' }}
      fixed
      backdropProps={{}}
    >
      <Navbar className="iso__header iso__header--mobile d-block d-lg-none">
        <TopHeader isOpen={open} toggleMenu={setOpen} {...props} />
        <CollapseMenuFixed isOpen={open} toggleMenu={setOpen} {...props} />
      </Navbar>
    </AlertWrapper>
  );
};

export default HeaderMobile;

const CollapseMenuFixed = (
  props: HeaderProps & { isOpen: boolean; toggleMenu: (a: boolean) => void }
) => {
  const { links, isOpen, toggleMenu } = props;
  const { linksKeys } = useHeaderHooks(props);
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const openLoginModal = () => {
    setLoginModalOpen(true);
  };

  return (
    <>
      <div className={`iso__header__collapse__box  z-100 ${isOpen ? 'menu--is-open' : ''}`}>
        <TopHeader {...props} isOpen={isOpen} toggleMenu={toggleMenu} />
        <div className="navlink-container">
          {links.map((link, index) => (
            <Link key={linksKeys[index]} {...link.linkProps}>
              <div>{link.label}</div>
            </Link>
          ))}
        </div>
        <div className="menu-sticky--down mt-6 pb-3 pt-3">
          <HeaderTop {...props} openLoginModal={openLoginModal} />
        </div>
      </div>
      <LoginModal
        isOpen={loginModalOpen}
        toggle={() => setLoginModalOpen(p => !p)}
        onSuccess={() => setLoginModalOpen(false)}
      />
    </>
  );
};

const TopHeader = ({
  isOpen,
  toggleMenu,
  isFixed,
  image,
  proImage
}: { isOpen: boolean; toggleMenu: (a: boolean) => void; isFixed?: boolean } & HeaderProps) => {
  const fixedClassName = isFixed ? 'top-header--fixed' : '';
  const auth = useAuth();

  return (
    <div
      className={`${fixedClassName} top-header--mobile d-flex justify-content-between align-items-center  z-102`}
    >
      <LeftMenu isOpen={isOpen} toggleMenu={toggleMenu} />
      <span className="image-container">
        {auth.authState.isLoggedIn && proImage && proImage['@link'] ? (
          <Link href={PageUrl.HOME}>
            <ImageWrapper
              className="image"
              image={proImage}
              alt={proImage.metadata?.caption || 'Logo'}
              width={100}
              height={100}
            />
          </Link>
        ) : (
          <Link href={PageUrl.HOME}>
            <ImageWrapper
              className="image"
              src={image['@link']}
              alt={image.metadata?.caption || 'Logo'}
              width={100}
              height={100}
            />
          </Link>
        )}
      </span>
      <span />
    </div>
  );
};

const LeftMenu = ({
  isOpen,
  toggleMenu
}: {
  isOpen: boolean;
  toggleMenu: (a: boolean) => void;
}) => {
  return (
    <span onClick={() => toggleMenu(!isOpen)} className="menu-icon p-2 hide-in-print">
      <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
    </span>
  );
};
