enum RenditionEnum {
  R_ORIGIN = 'ORIGIN',
  R_1600 = '1600',
  R_1200 = '1200',
  R_1024 = '1024',
  R_640 = '640',
  R_426 = '426'
}

export default RenditionEnum;
