import React from 'react';
import styles from './RichText.module.scss';
import sanitize from '@/Util/sanitize';

interface Props {
  richText: string;
}

const RichText = (props: Props): JSX.Element => {
  return (
    <div className={`RichText ${styles.RichText}`}>
      <div dangerouslySetInnerHTML={{ __html: sanitize(props.richText, true) }} />
    </div>
  );
};

export default RichText;
