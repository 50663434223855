import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './CommunicationButton.module.scss';
import { useIsOnProductPage } from '@ibe/components';

export interface CommunicationButtonProps {
  onCommunicationButtonClickAction: () => void;
}

const CommunicationButton = (props: CommunicationButtonProps) => {
  const { onCommunicationButtonClickAction } = props;
  const { isOnProductPage } = useIsOnProductPage();

  return (
    <Button
      className={`${styles.communicationButton} ${
        isOnProductPage && styles.communicationButton__alt
      }`}
      onClick={onCommunicationButtonClickAction}
    >
      <FontAwesomeIcon icon={faCommentAlt} />
    </Button>
  );
};

export default CommunicationButton;
