import React from 'react';
import { BookingDetailsModule } from '@ibe/components';
import Keys from '@/Translations/generated/en/booking-details.json.keys';
import useTranslationMGL from '@/Util/useTranslationMgl';
import { QueryUtils } from '@/Util/QueryUtils';
import useQuery from '@/Util/useQuery';
import Link from 'next/link';

interface Props {
  bookingListLink?: string;
}

const BookingOverview = ({ bookingListLink }: Props): JSX.Element => {
  const { t } = useTranslationMGL('booking-details');

  const bookingId = QueryUtils.getBookingId(useQuery()) || '';

  return (
    <div className={'iso__bookingDetails'}>
      <Link href={bookingListLink || ''} className="btn btn-primary goBackButton">
        {t(Keys.backToList)}
      </Link>
      <div className="mt-5">
        <BookingDetailsModule bookingId={bookingId} hideCancelOption={true} />
      </div>
    </div>
  );
};

export default BookingOverview;
