import React, { useState } from 'react';
import useTranslation from '@/Util/useTranslation';
import { ApiInventory } from '@ibe/api';
import Image from 'next/image';

interface Props {
  flightInfo?: ApiInventory;
  isMobile: boolean;
  flightArrival?: string;
}

const InternalFlightInfo: React.FC<Props> = ({ flightInfo, isMobile, flightArrival }) => {
  const isInternalFlight = flightInfo === 'TOP';
  const [isHovered, setIsHovered] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const { t } = useTranslation('bestPricePackageListItemFlightInternal');
  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const getTranslatedText = (keyBase: string) => {
    const key = `${keyBase}_${flightArrival}`;
    const keyValue = t(key);
    return keyValue === key ? `${keyBase}_Default` : keyValue;
  };

  return isInternalFlight ? (
    <div>
      <button
        className="internalFlightInfo"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={togglePopup}
      >
        <Image
          src="/placeholder/icons/airplane.svg"
          alt="Flight Icon"
          className="internalFlightInfo__icon"
          width={20}
          height={20}
          loader={p => p.src}
        />
        <div className="internalFlightInfo__text">
          {getTranslatedText('internalFlightInfoButtonText')}
        </div>
      </button>
      {isHovered && !isMobile && !isPopupVisible && (
        <div className="internalFlightInfo__description">
          <div className="internalFlightInfo__description__content">
            <div className="internalFlightInfo__description__content__header">
              {getTranslatedText('internalFlightInfoHeader')}
            </div>
            <div className="internalFlightInfo__description__content__text">
              {getTranslatedText('internalFlightInfoText')}
            </div>
          </div>
        </div>
      )}
      {isPopupVisible && (
        <>
          <div className="internalFlightInfo__overlay" onClick={togglePopup} />
          <div className="internalFlightInfo__popup">
            <div className="internalFlightInfo__popup__content">
              <div className="internalFlightInfo__popup__content__header">
                <div className="internalFlightInfo__popup__content__header__headerText">
                  {getTranslatedText('internalFlightInfoHeader')}
                </div>
                <button
                  className="internalFlightInfo__popup__content__header__headerClose"
                  onClick={togglePopup}
                >
                  <Image
                    src="/placeholder/icons/close-popup.svg"
                    alt="Close Icon"
                    width={24}
                    height={24}
                    loader={p => p.src}
                  />
                </button>
              </div>
              <div className="internalFlightInfo__popup__content__text">
                {getTranslatedText('internalFlightInfoText')}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  ) : null;
};

export default InternalFlightInfo;
