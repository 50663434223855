import React from 'react';
import { EditableArea } from '@magnolia/react-editor';

const Accordion = (props: { accordionArea?: object }): JSX.Element => {
  const { accordionArea } = props;

  return (
    <div className="accordion">
      {!!accordionArea ? <EditableArea content={accordionArea} /> : <></>}
    </div>
  );
};

export default Accordion;
