import React from 'react';
import { observer } from 'mobx-react';
import DefaultPageLayout, { Props as DefaultPageLayoutProps } from './DefaultPageLayout';
import { MagnoliaAreaNode } from '@/Util/magnolia';

export interface Props extends DefaultPageLayoutProps {
  // areas
  header: MagnoliaAreaNode;
  top: MagnoliaAreaNode;
  main: MagnoliaAreaNode;
  footer: MagnoliaAreaNode;
}

const StaticPageLayout = observer((props: Props) => {
  return <DefaultPageLayout {...props}>{props.children}</DefaultPageLayout>;
});

export default StaticPageLayout;
