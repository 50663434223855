import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import { Props } from '@/Layouts/StaticPageLayout';
import BreadCrumb from '@/components/breadCrumb/BreadCrumb';

export const StaticPage = (props: Props): JSX.Element => {
  const { header, top, main, footer } = props;

  return (
    <>
      <div className="page__top">
        {header && <EditableArea className="page__top__header" content={header} />}
        {top && <EditableArea content={top} />}
      </div>
      <div className="page__center">
        <div className="max-width">
          <div className="ml-2 mr-2 mr-lg-5 ml-lg-5 mt-1 mt-md-5">
            <BreadCrumb />
            {main && <EditableArea content={main} />}
          </div>
        </div>
      </div>
      <div className="page__bottom">{footer && <EditableArea content={footer} />}</div>
    </>
  );
};

export default StaticPage;
