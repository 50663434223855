import { FormConfig } from '@ibe/components';
import Keys from '@/Translations/generated/en/newsletter.json.keys';
import { TFunction } from 'i18next';
import { sanitize } from '@ibe/services';

export const termsAndConditionsKey = 'termsAndConditions';

const newsletterFormConfig = (t: TFunction, termsAndConditions?: string): FormConfig => {
  return {
    rows: [
      {
        key: 'row1',
        elements: [
          {
            tag: 'input',
            key: 'firstName',
            label: t(Keys.firstName.label),
            placeholder: t(Keys.firstName.placeholder),
            rowGrid: {
              md: 4
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.requiredField)
                }
              ]
            }
          },
          {
            tag: 'input',
            key: 'lastName',
            label: t(Keys.lastName.label),
            placeholder: t(Keys.lastName.placeholder),
            rowGrid: {
              md: 4
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.requiredField)
                }
              ]
            }
          },
          {
            tag: 'input',
            key: 'email',
            label: t(Keys.email.label),
            placeholder: t(Keys.email.placeholder),
            rowGrid: {
              md: 4
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.requiredField)
                },
                {
                  type: 'email',
                  customMessage: t(Keys.emailError)
                }
              ]
            }
          },
          ...(termsAndConditions
            ? [
                {
                  tag: 'input',
                  type: 'checkbox',
                  key: termsAndConditionsKey,
                  className: 'termsAndConditions',
                  label: termsAndConditions,
                  rowGrid: {
                    md: 12
                  }
                } as FormConfig['rows'][0]['elements'][0]
              ]
            : [])
        ]
      }
    ]
  };
};

export default newsletterFormConfig;
