import React, {
  Dispatch,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Element as ScrollTo } from 'react-scroll';
import { Button, Col, CustomInput, Row } from 'reactstrap';
import Keys from '@/Translations/generated/en/Checkout.json.keys';
import {
  ApiBaseData,
  ApiBooking,
  ApiComponentType,
  ApiHotelRoom,
  ApiItemType,
  ApiRoomRate,
  ApiTraveler,
  ApiTravelerType
} from '@ibe/api';
import {
  appService,
  FormBuilder,
  FormRef,
  MEDIAQUERY_DEFAULTS,
  useAuth,
  useConfig,
  useMediaQuery
} from '@ibe/components';
import { FormItemsProps } from '../../Config/travellerFormConfig';
import { getFormConfig, getSalutations } from './externals';
import { scrollElementErrorTravelerBase } from './Traveler';
import { getTravelerMockData } from '../../Config/travellerFormConfig';
import useTranslationMGL from '@/Util/useTranslationMgl';
import { getTravelerMockDataSmall } from '@/Config/travellerFormConfigSmall';

interface Props {
  travelers: ApiTraveler[];
  setTravelers: Dispatch<React.SetStateAction<ApiTraveler[]>>;
  booking: ApiBooking | null;
  formRefs: MutableRefObject<FormRef<Record<string, unknown>>[]>;
  externalData: {
    COUNTRIES: ApiBaseData[];
    SALUTATIONS: ApiBaseData[];
  };
  initialValues?: Array<{ [key: string]: string }>;
}

const TravelerForms = (props: Props): JSX.Element => {
  const { travelers, setTravelers, booking, formRefs, externalData, initialValues } = props;
  const { t } = useTranslationMGL('Checkout');
  const tBuilder = useTranslationMGL('traveller-builder');
  const config = useConfig();
  const auth = useAuth();
  const params = new URLSearchParams(window?.location?.search || '');
  const lang = params?.get('lng') || appService.lang;
  const dateFormat = config.displayFormatDate[lang.replace('-', '_')];
  const isMobileView = useMediaQuery({ type: 'max', query: MEDIAQUERY_DEFAULTS.md });
  const bookedHotelRooms = booking?.bookedItems?.filter(
    item => item.itemType === ApiItemType.HOTEL
  );

  const getSortedTravelersPerRoom = (travelersIds?: string[]): ApiTraveler[] => {
    if (!travelersIds) return [];
    const findTravelers = travelers.filter(tra => travelersIds.includes(tra.id));
    return [
      ...findTravelers.filter(traveler => traveler.type === ApiTravelerType.ADULT),
      ...findTravelers.filter(traveler => traveler.type === ApiTravelerType.CHILD),
      ...findTravelers.filter(
        traveler =>
          traveler.type !== ApiTravelerType.ADULT && traveler.type !== ApiTravelerType.CHILD
      )
    ];
  };

  const [travelerApplicant, setTravelerApplicant] = useState(
    getSortedTravelersPerRoom(bookedHotelRooms?.[0].paxRequirement.paxes?.travelersIds)?.[0]
  );
  const [initialMockValues, setInitialMockValues] = useState<Record<string, unknown>[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (!!initialMockValues) {
      formRefs.current.map((ref: FormRef<Record<string, unknown>>, idx: number) => {
        ref?.resetForm(initialMockValues[idx] || initialMockValues[0]);
      });
    }
  }, [initialMockValues]);

  const onFormFieldsChange = useCallback(
    (values: Record<string, string>, id: string) => {
      const formValues = values as FormItemsProps;
      const changedTraveler = travelers.find(traveler => traveler.id === id);

      if (!!changedTraveler && changedTraveler.firstName !== formValues.firstname) {
        setTravelers(value =>
          value.map(traveler => ({
            ...traveler,
            firstName: traveler.id === id ? formValues.firstname : traveler.firstName
          }))
        );
      }
      if (!!changedTraveler && changedTraveler.lastName !== formValues.lastname) {
        setTravelers(value =>
          value.map(traveler => ({
            ...traveler,
            lastName: traveler.id === id ? formValues.lastname : traveler.lastName
          }))
        );
      }
    },
    [travelers]
  );

  return (
    <div className="travellerData">
      <div className="traveler-header font-weight-bold mb-4">{t(Keys.participantData)}</div>
      {config?.traveler?.mockAvailable && (
        <div className="d-flex justify-content-end">
          <Button
            color="secondary"
            className="mb-4 mr-3"
            onClick={(): void => {
              setInitialMockValues(
                travelers.map(value => {
                  if (travelerApplicant?.id === value.id && !auth.authState.isLoggedIn) {
                    return getTravelerMockData();
                  } else {
                    return getTravelerMockDataSmall();
                  }
                })
              );
            }}
          >
            Fill with mock data
          </Button>
        </div>
      )}
      {bookedHotelRooms?.map(room => {
        const assignedBookedRoomRate = booking?.items.find(
          item => item.id === room?.idParent
        ) as ApiRoomRate;

        const assignedBookedRoom = booking?.items.find(
          item => item.id === assignedBookedRoomRate?.idParent
        ) as ApiHotelRoom;

        return (
          <div className="roomContainer mb-4" key={room.id}>
            {assignedBookedRoom && assignedBookedRoomRate ? (
              <div className="roomInfo">
                <Col>
                  <span className="font-weight-bold">
                    {t(Keys.room)}&nbsp;{bookedHotelRooms.findIndex(r => r == room) + 1}:
                  </span>
                  <span>
                    {` ${assignedBookedRoom.description}, ${assignedBookedRoomRate.mealTypeDescription} `}{' '}
                  </span>
                </Col>
              </div>
            ) : null}

            {getSortedTravelersPerRoom(room.paxRequirement.paxes?.travelersIds).map(
              (traveler, idx) => {
                const bookedRoom = booking?.bookedItems.find(
                  bookedItem =>
                    bookedItem.componentType === ApiComponentType.MAIN &&
                    bookedItem.priceByPersonId[traveler.id]
                );

                const travelIndex = travelers.findIndex(tra => tra.id === traveler.id);

                return (
                  <ScrollTo key={traveler.id} name={scrollElementErrorTravelerBase + idx}>
                    <div className="pb-4 traveller--entry" key={traveler.id}>
                      <div className="form-module__headline mb-3">
                        <Row>
                          <Col lg={2} sm={3}>
                            <div>
                              {idx + 1}&nbsp;
                              <span className="form-module__headline--suffix">
                                {t(Keys.participant)}
                              </span>
                            </div>
                          </Col>
                          {traveler.type === ApiTravelerType.ADULT &&
                          !!room.paxRequirement.paxes?.travelersIds &&
                          traveler.id === room.paxRequirement.paxes?.travelersIds[0] &&
                          !auth.authState.isLoggedIn ? (
                            <Col lg={10} sm={9}>
                              <div>
                                <CustomInput
                                  type="radio"
                                  checked={
                                    travelerApplicant?.id ==
                                    getSortedTravelersPerRoom(
                                      room.paxRequirement.paxes?.travelersIds
                                    )[0].id
                                  }
                                  onChange={(): void =>
                                    setTravelerApplicant(
                                      getSortedTravelersPerRoom(
                                        room.paxRequirement.paxes?.travelersIds
                                      )[0]
                                    )
                                  }
                                  label={t(Keys.applicant)}
                                  id={room.id}
                                />
                              </div>
                            </Col>
                          ) : (
                            ''
                          )}
                        </Row>
                      </div>
                      <FormBuilder
                        key={traveler.id}
                        formConfig={getFormConfig(
                          travelerApplicant.id === traveler.id && !auth.authState.isLoggedIn,
                          traveler.type,
                          tBuilder.t,
                          !!initialValues ? initialValues[travelIndex] : undefined,
                          dateFormat,
                          isMobileView
                        )}
                        onFormFieldsChange={(values: Record<string, string>) =>
                          onFormFieldsChange(values, traveler.id)
                        }
                        // eslint-disable-next-line
                        // @ts-ignore
                        ref={(ref: FormRef<Record<string, unknown>> | null): void => {
                          if (ref) {
                            formRefs.current[travelIndex] = ref;
                          }
                        }}
                        hideSubmitButton
                        scrollToError
                        initialValues={{ id: traveler.id }}
                        externalData={{
                          countries: externalData.COUNTRIES,
                          salutations: getSalutations(externalData.SALUTATIONS, traveler.type),
                          yupContext: {
                            adultMinAge: config.traveler.maxChildAge + 1,
                            childMinAge: config.traveler.maxInfantAge + 1,
                            startDate: booking?.travelStartDate
                          }
                        }}
                        mode="onChange"
                        reValidateMode="onChange"
                      >
                        <div />
                        <div />
                      </FormBuilder>
                      <Row>
                        <Col md={12} className="d-flex justify-content-end">
                          <div className="form-module__lower-label">{t(Keys.requiredFields)}</div>
                        </Col>
                      </Row>
                    </div>
                  </ScrollTo>
                );
              }
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TravelerForms;
