import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import HeaderDesktop from './Desktop';
import HeaderMobile from './Mobile';
import { MagnoliaDamNode, MagnoliaPageNode } from '@/Util/magnolia';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { LinkPropsType, getNextLinkAttrs, SwitchableLinkProps } from '@/components/linkList/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { createPortal } from 'react-dom';
import { useAuth, useWindow } from '@ibe/components';
import ImageWrapper from '@/components/ImageWrapper';
import { useMount } from 'react-use';
import { configService } from '@/Config/config';
import PageUrl from '@/Models/PageUrl';
import { useRouter } from 'next/router';

export interface HeaderProps {
  links: {
    linkProps: LinkPropsType;
    label: string;
  }[];
  loginLabel: string;
  logoutLabel: string;
  bookingListLabel?: string;
  bookingListLink?: string;
  image: MagnoliaDamNode;
  proImage?: MagnoliaDamNode;
}

interface MagnoliaPageNodeHeader extends Omit<MagnoliaPageNode, '@nodes'> {
  '@nodes': string[];
}

interface HeaderPropsCMS extends MagnoliaPageNodeHeader {
  bottomNav: { [a: string]: SwitchableLinkProps } & SwitchableLinkProps;
  imageLink: MagnoliaDamNode;
  proImageLink?: MagnoliaDamNode;
  loginLabel: string;
  logoutLabel: string;
  bookingListLabel?: string;
  bookingListLink?: string;
  emailPrintView?: string;
}
const Header = observer((props: HeaderPropsCMS) => {
  const { headerNav } = useGlobalMGLProps() || {};
  const {
    bottomNav,
    imageLink,
    proImageLink,
    loginLabel,
    logoutLabel,
    bookingListLabel,
    bookingListLink,
    emailPrintView
  } = props;
  const config = configService.get();
  const auth = useAuth();
  const router = useRouter();
  const window = useWindow();
  const detailsPageLogoContainer = window?.document?.querySelector(
    '.best-price-package-details__logo'
  );

  const storageListener = (e: StorageEvent) => {
    if (auth.authState.isLoggedIn && config.sessionKeyTabsShared) {
      if (e.key === config.sessionKeyAuth && e.oldValue && e.newValue === null) {
        auth.removeToken();
        auth.authService.invalidate();
        router.push(PageUrl.HOME).then(() => null);
      }
    }
  };

  useMount(() => {
    window?.addEventListener('storage', storageListener);
    return () => {
      window?.removeEventListener('storage', storageListener);
    };
  });

  const data = useMemo((): HeaderProps => {
    return {
      links: [
        ...(bottomNav?.['@nodes'] || []).map(node => {
          return { linkProps: getNextLinkAttrs(bottomNav[node]), label: bottomNav[node].title };
        }),
        ...(headerNav || []).map(page => {
          return {
            label: page.title,
            linkProps: { href: page.url }
          };
        })
      ],
      loginLabel: loginLabel,
      logoutLabel: logoutLabel,
      bookingListLabel: bookingListLabel,
      bookingListLink: bookingListLink,
      image: imageLink,
      proImage: proImageLink
    };
  }, [props]);
  return (
    <div className="iso__header__wrapper">
      <div className="horizontal-spread ml-lg-4 mr-lg-4 mr-lg-7 ml-lg-7 hide-in-print">
        <HeaderDesktop {...data} />
        <HeaderMobile {...data} />
      </div>
      <div className="best-price-package-details__print-footer hide-in-screen">
        <div className="d-flex flex-column align-items-center">
          {!!data.image && (
            <ImageWrapper
              className="image"
              image={data.image}
              alt={data.image.metadata?.caption || 'Logo'}
              width={100}
              height={100}
            />
          )}
          {!!emailPrintView && (
            <div className="best-price-package-details__print-footer__email">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              <span>{emailPrintView}</span>
            </div>
          )}
        </div>
      </div>
      {!!detailsPageLogoContainer ? (
        createPortal(
          <ImageWrapper
            className="image"
            image={auth.authState.isLoggedIn && !!data.proImage ? data.proImage : data.image}
            alt={
              (auth.authState.isLoggedIn && !!data.proImage ? data.proImage : data.image).metadata
                ?.caption || 'Logo'
            }
            width={100}
            height={100}
          />,
          detailsPageLogoContainer
        )
      ) : (
        <></>
      )}
    </div>
  );
});

export default Header;
