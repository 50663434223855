import React from 'react';
import {
  LinkSwitchableMixin,
  MagnoliaComponentNode,
  MagnoliaDamNode,
  customImageSizeSwitchableMixin
} from '@/Util/magnolia';
import styles from './Image.module.scss';
import Link from 'next/link';
import { getNextLinkAttrs } from '../linkList/Link';
import ImageWrapper from '@/components/ImageWrapper';
import { DateInfoType } from '../ibe/IbeProps';
import PackageParamsTransformer from '@/Util/ParamsTransformer/PackageParamsTransformer';
import { DestinationItem, PackageParams, PackageSearchParams, useConfig } from '@ibe/components';
import { getDatesFromProps } from '@/components/offer/Offer';
import YsnConfigModel from '@/Config/YsnConfigModel';

export interface paxContainersTypeProps extends Omit<MagnoliaComponentNode, '@nodes'> {
  '@nodes': string[];
  numberOfAdults: string;
  numberOfChildren: string;
  numberOfInfants: string;
}

interface Props extends customImageSizeSwitchableMixin {
  image: MagnoliaDamNode;
  imageAltText?: string;
  imageCaption?: string;
  keepAspectRatio?: boolean;
  imageCredit?: string;
  imageTitle?: string;
  metadata: MagnoliaComponentNode;
  openNewBrowserWindow?: boolean;
  searchInfo?: {
    dateInfo: DateInfoType;
    destinations?: string;
    packageCode?: string;
    preSelectedCharacteristics: string;
    type: 'search' | 'link';
    ibeUrl: string;
    paxContainers: paxContainersTypeProps & { [key: string]: paxContainersTypeProps };
  } & LinkSwitchableMixin;
}

const Image = (props: Props & customImageSizeSwitchableMixin): JSX.Element => {
  const config = useConfig();
  if (!props?.searchInfo) return <></>;
  const { linkTypedownload, linkTypeexternal, linkTypepage } = props.searchInfo || {};
  const {
    searchInfo,
    image,
    imageAltText,
    imageTitle,
    imageCaption,
    imageSize,
    keepAspectRatio,
    imageSizecustomImageWidth,
    imageSizecustomImageHeight,
    imageCredit
  } = props;
  const imageElement = (
    <ImageWrapper
      className={imageSize}
      style={keepAspectRatio ? { objectFit: 'contain' } : {}}
      image={image}
      width={imageSize === 'custom' ? imageSizecustomImageWidth! : image?.metadata?.width}
      height={imageSize === 'custom' ? imageSizecustomImageHeight! : image?.metadata?.height}
    />
  );
  const url = searchInfo?.type === 'search' ? getSearchUrl(props, config) : '';
  let linkAttrs = getNextLinkAttrs(props?.searchInfo);
  if (searchInfo?.type === 'search' && url) {
    linkAttrs = { ...linkAttrs, href: url };
  }
  return (
    <figure className={styles.Image}>
      {(linkAttrs?.href &&
        (searchInfo?.type === 'search' || linkTypedownload || linkTypeexternal || linkTypepage) && (
          <Link {...linkAttrs} locale={linkAttrs.download === true ? false : undefined}>
            {imageElement}
          </Link>
        )) ||
        imageElement}
      {(imageCaption || imageCredit) && (
        <figcaption className={styles.Image__caption}>
          <span>{imageCaption ?? ''}</span>
          {imageCredit && <span>{imageCredit}</span>}
        </figcaption>
      )}
    </figure>
  );
};

const getSearchUrl = ({ searchInfo }: Props, config: YsnConfigModel): string => {
  let url = '';
  if (searchInfo) {
    const { destinations, packageCode } = searchInfo;
    const [startDate, endDate] = getDatesFromProps(searchInfo, config);
    const { paxContainers, preSelectedCharacteristics } = searchInfo;
    const params: PackageSearchParams = {
      destinations: (destinations || '')
        .split(',')
        .filter(dest => dest)
        .flatMap(dest => ({ code: dest } as DestinationItem)),
      endDate,
      startDate,
      packageCode,
      occupancy: (paxContainers?.['@nodes'] || []).flatMap(paxContainer => {
        const numberOfAdults = paxContainers[paxContainer].numberOfAdults;
        const numberOfChildren = paxContainers[paxContainer].numberOfChildren;
        const numberOfInfants = paxContainers[paxContainer].numberOfInfants;
        const children = numberOfChildren ? parseInt(numberOfChildren) : 0;
        const infants = numberOfInfants ? parseInt(numberOfInfants) : 0;

        const infantsAges: number[] = numberOfInfants
          ? Array(parseInt(numberOfInfants)).fill(config.traveler.maxInfantAge - 1)
          : [];
        const childrenAges: number[] = children
          ? Array(children).fill(config.traveler.maxChildAge - 1)
          : [];

        return {
          adults: numberOfAdults ? parseInt(numberOfAdults) : 2,
          children: children + infants,
          childrenAges: childrenAges.concat(infantsAges),
          infants: 0,
          infantsAges: []
        };
      }),
      preSelectedCharacteristics: (preSelectedCharacteristics || '')
        .split(',')
        .filter(dest => dest),
      goToDetails: true
    };
    const queryParams = PackageParamsTransformer.encode(params);
    url = `${searchInfo?.ibeUrl}?${queryParams}`;
  }

  return url;
};

export default Image;
