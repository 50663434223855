import { BestPriceAvailabilityStore, DateDisplay, useMediaQuery } from '@ibe/components';
import Keys from '../../../Translations/generated/en/search-content.json.keys';
import KeysPack from '../../../Translations/generated/en/PackageSearch.json.keys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faPlaneDeparture,
  faClock,
  faUserFriends
} from '@fortawesome/free-solid-svg-icons';
import { getNumber } from './RenderSearchContent';
import useTranslation from '../../../Util/useTranslation';

const BestPriceSearchContent = (store: BestPriceAvailabilityStore): JSX.Element => {
  const { t } = useTranslation('search-content');
  const { t: tPack } = useTranslation('PackageSearch');
  const isMobile = useMediaQuery({ query: '576', type: 'max' });

  const searchParams = store.searchParams;
  if (!searchParams) return <></>;

  const departureMobile = isMobile
    ? `${searchParams?.origins?.[0]?.name || ''}${searchParams?.origins?.length ? ', ...' : ''}`
    : '';

  const getNumberOfNight = (number: number): string => {
    const rest = number % 7;
    if (rest) return `${number} ${tPack(KeysPack.night, { count: number })}`;
    return `${number / 7} ${tPack(KeysPack.week, { count: number / 7 })}`;
  };
  const numberOfNights = getNumberOfNight(searchParams.duration || 0);
  return (
    <div className="package__search__content">
      <div>
        <FontAwesomeIcon icon={faPlaneDeparture} />
        {departureMobile ||
          searchParams.origins?.map(origin => origin.name).join(', ') ||
          tPack(KeysPack.arbitrary)}
      </div>
      <div>
        {searchParams.startDate && searchParams.endDate && (
          <>
            <FontAwesomeIcon icon={faCalendarAlt} />
            <DateDisplay date={searchParams.startDate} />
            {searchParams.endDate ? (
              <>
                {' / '}
                <DateDisplay date={searchParams.endDate} />
              </>
            ) : null}
          </>
        )}
      </div>
      <div>
        <FontAwesomeIcon icon={faClock} />
        {numberOfNights}
      </div>
      {!!searchParams.occupancy && (
        <div>
          <FontAwesomeIcon icon={faUserFriends} />
          {`${getNumber(searchParams.occupancy, 'adults')} ${t(Keys.adult, {
            count: getNumber(searchParams.occupancy, 'adults')
          })}${
            getNumber(searchParams.occupancy, 'children') > 0
              ? `, ${getNumber(searchParams.occupancy, 'children')} ${t(Keys.child, {
                  count: getNumber(searchParams.occupancy, 'children')
                })}`
              : ''
          }`}
        </div>
      )}
    </div>
  );
};

export default BestPriceSearchContent;
