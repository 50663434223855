import React, { useState } from 'react';
import { Error, FormBuilder, LoadingOverlay, useApi } from '@ibe/components';
import { Col, Row } from 'reactstrap';
import sanitize from '@/Util/sanitize';
import Keys from '@/Translations/generated/en/newsletter.json.keys';
import newsletterFormConfig, { termsAndConditionsKey } from '@/Config/newsletterFormConfig';
import useTranslationMGL from '@/Util/useTranslationMgl';
import { ApiActivity, ApiActivityType } from '@ibe/api';
import { useRouter } from 'next/router';

interface Props {
  text?: string;
  termsAndConditions?: string;
  _successfulUrl: string;
  failureUrl: string;
}

const Newsletter = ({
  _successfulUrl: successfulUrl,
  failureUrl,
  text,
  termsAndConditions
}: Props) => {
  const { t } = useTranslationMGL('newsletter');
  const api = useApi();
  const router = useRouter();

  const [showTermsAndConditionsError, setShowTermsAndConditionsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitNewsletter = (values: Record<string, string>) => {
    if (termsAndConditions && !values[termsAndConditionsKey]) {
      return setShowTermsAndConditionsError(true);
    }
    setIsLoading(true);
    const activityText = `A user requests a newsletter subscription: ${values.firstName}, ${values.lastName}, ${values.email}`;

    const activity: ApiActivity = {
      text: activityText,
      type: ApiActivityType.NEWSLETTERSUBSCRIPTION
    };

    api
      .createActivity(activity)
      .then(() => router.push(successfulUrl))
      .catch(() => router.push(failureUrl))
      .finally(() => setIsLoading(false));
  };

  return (
    <LoadingOverlay isLoading={isLoading}>
      <div className={'newsletter-wrapper'}>
        <div className={'newsletter'}>
          {text && <div dangerouslySetInnerHTML={{ __html: sanitize(text) }} />}
          <Row className={'d-flex align-items-center newsletter__form-container'}>
            <Col className="newsletter__form">
              <FormBuilder
                submitButtonText={t(Keys.signUp)}
                formConfig={newsletterFormConfig(t, termsAndConditions)}
                onSubmit={submitNewsletter}
              />
            </Col>
          </Row>
          {showTermsAndConditionsError && (
            <Row>
              <Col>
                <div className="my-1">
                  <Error message={t(Keys.termsAndConditionsError)} />
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Newsletter;
