import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  BookingSummary,
  LoadingOverlay,
  SessionData,
  useApi,
  useBookingService,
  useConfig
} from '@ibe/components';
import { ApiBooking, ApiHotel, ApiItemType } from '@ibe/api';
import {
  checkoutFinishedEvent,
  LoggerFactory,
  MaskBookingService,
  SessionPersistence
} from '@ibe/services';
import { useMount } from 'react-use';
import Keys from '@/Translations/generated/en/Checkout.json.keys';
import { CheckoutStepComponentProps } from '@/Models/CheckoutStep';
import useTranslationMGL from '@/Util/useTranslationMgl';
import { useRouter } from 'next/router';
import { useWorkflowService } from '@/Util/contexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import CheckoutStore from '@/components/checkout/CheckoutStore';

interface PackageCartSessionData {
  packageCartId: string;
}

const Confirmation = observer((props: CheckoutStepComponentProps) => {
  const logger = LoggerFactory.get('Confirmation');
  const { paymentResponse } = useWorkflowService();
  const bs = useBookingService();
  const config = useConfig();
  const { t } = useTranslationMGL('Checkout');
  const router = useRouter();
  const [retrievedBooking, setRetrievedBooking] = useState<ApiBooking | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hotelItem, setHotelItem] = useState<ApiHotel | undefined>();
  const showFlightNumber = true;

  const api = useApi();

  const clearBooking = async (): Promise<void> => {
    const sessionBooking = new SessionPersistence<SessionData>(SessionData);
    sessionBooking.useKey(config.sessionKeyCart);
    sessionBooking.clear();
    const sessionPackageCart = new SessionPersistence<PackageCartSessionData>();
    sessionPackageCart.useKey(CheckoutStore.PACKAGE_CART_ID);
    sessionPackageCart.clear();
    bs.clearBooking();
  };

  const retrieveBooking = useCallback(async (): Promise<void> => {
    const bookingId = paymentResponse?.bookingId || (router.query.bookingId as string);
    if (bookingId != null) {
      let res: ApiBooking | null = null;
      try {
        res = await api.getBooking(bookingId);
        checkoutFinishedEvent.broadcast({
          booking: MaskBookingService.maskBooking(res),
          item: null
        });
        setRetrievedBooking(res);
        if (!router.query.bookingId && paymentResponse) {
          router.query.bookingId = paymentResponse.bookingId as string;
          router.push(router);
        }
      } catch (e) {
        logger.error(e);
      }
    }
  }, [api, paymentResponse?.bookingId, logger]);

  useMount(() => {
    (async (): Promise<void> => {
      setIsLoading(true);
      await Promise.all([await retrieveBooking(), await clearBooking()]);
      setIsLoading(false);
    })();
  });

  useEffect(() => {
    setHotelItem(
      retrievedBooking?.items.find(value => value.itemType === ApiItemType.HOTEL) as ApiHotel
    );
  }, [retrievedBooking]);

  return (
    <>
      <LoadingOverlay isLoading={isLoading}>
        <div className={`confirmationContainer is-${retrievedBooking?.bookingState}`}>
          {hotelItem && (
            <div className="position-relative">
              <img
                className="confirmationContainer__image"
                src={hotelItem?.media?.mainImage.large.url || ''}
                alt={hotelItem?.media?.mainImage.large.altText || ''}
              />
              <div className="confirmationContainer__image__text">{hotelItem.name}</div>
            </div>
          )}
          <div className="confirmationContainer__success">
            <div className="confirmationContainer__success__headline confirmationContainer__success__headline__image">
              <FontAwesomeIcon icon={faCheckCircle} />
            </div>
            <div className="ml-2">
              {!paymentResponse?.hasPaymentError && !paymentResponse?.paymentWasCancelled && (
                <>
                  <div className="confirmationContainer__success__headline text-break">
                    {retrievedBooking?.bookingState === 'QUOTE'
                      ? t(Keys.ConfirmationQuotationHeadline)
                      : t(Keys.ConfirmationHeadline)}
                  </div>
                </>
              )}
              {retrievedBooking && retrievedBooking.bookingNumber && (
                <>
                  <div className="text-break">
                    <span className="confirmationContainer__success__bookingNumber__description">
                      {retrievedBooking.bookingState === 'QUOTE'
                        ? t(Keys.ConfirmationQuotationBookingNumber)
                        : t(Keys.ConfirmationBookingNumber)}
                    </span>
                    <span className="confirmationContainer__success__bookingNumber__number ml-1">
                      {retrievedBooking.bookingNumber}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          {retrievedBooking && (
            <BookingSummary booking={retrievedBooking} showFlightNumber={showFlightNumber} />
          )}
        </div>
      </LoadingOverlay>
    </>
  );
});

export default Confirmation;
