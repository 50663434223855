import React, { useState } from 'react';
import { EditableArea } from '@magnolia/react-editor';
import { Button, Card, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import styles from './Accordion.module.scss';

const AccordionElement = (props: { accordionBody: object; headerText: string }): JSX.Element => {
  const { accordionBody, headerText } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={styles.accordion__element}>
      <Button
        className={styles.accordion__element__button}
        onClick={(): void => setIsOpen(value => !value)}
      >
        <span>{headerText}</span>
        <FontAwesomeIcon
          className={`${styles.accordion__element__chevron} ${
            isOpen ? styles.accordion__element__chevronUp : ''
          }`}
          icon={faChevronDown}
        />
      </Button>
      <Collapse isOpen={isOpen}>
        <Card className={styles.accordion__element__card}>
          {!!accordionBody ? <EditableArea content={accordionBody} /> : <></>}
        </Card>
      </Collapse>
    </div>
  );
};

export default AccordionElement;
