import { ApiAllowedBaggage, ApiBaggageUnitType, ApiSpecialRequest } from '@ibe/api';
import { formatPrice } from '@ibe/services';
import { TFunction } from 'i18next';

class LuggageLabelFactory {
  private readonly _tFunction: TFunction;
  private _keys: { [key: string]: string };
  private readonly _language: string;

  constructor(tFunction: TFunction, keys: { [p: string]: string }, language: string) {
    this._tFunction = tFunction;
    this._keys = keys;
    this._language = language;
  }

  public create = (specialRequest: ApiSpecialRequest | undefined): string => {
    let label = '';
    if (specialRequest?.meta) {
      if (specialRequest.meta['ssrText']) {
        label += specialRequest.meta['ssrText'];
      } else {
        if (specialRequest.meta['maxPieces']) {
          label += specialRequest.meta['maxPieces'];
        }
        if (specialRequest.meta['quantity']) {
          if (specialRequest.meta['quantity'] === '1') {
            const count = Number(specialRequest.meta['maxPieces']);
            label += this._tFunction(this._keys.freeBaggageIncluded, { count });
          } else {
            label += label.length
              ? ' * ' + specialRequest.meta['quantity']
              : specialRequest.meta['quantity'];
          }
        }
        if (specialRequest.meta['unit']) {
          label += ' ' + this._tFunction(this._keys.kilo, '');
        }
        if (specialRequest.meta['type']) {
          label += ' ' + this._tFunction(specialRequest.meta['type'].toLowerCase(), ' ');
        }
      }
      if (specialRequest.fee) {
        label +=
          ' : ' +
          formatPrice(
            specialRequest.fee.finalPrice,
            specialRequest.fee.currencyCode,
            this._language
          );
      }
    }
    return label;
  };

  public createIncluded = (baggage: ApiAllowedBaggage): string => {
    const unitTypeText =
      baggage.unit === ApiBaggageUnitType.KG
        ? this._tFunction(this._keys.kilo, '')
        : this._tFunction(this._keys.piece, '');
    if (!baggage.amount) {
      return this._tFunction(this._keys.freeBaggageIncluded, { count: baggage?.maxPieces || 1 });
    } else if (baggage.amount > 1) {
      return `${baggage.maxPieces} * ${baggage.amount}${
        !!unitTypeText ? unitTypeText : baggage.unit
      }`;
    }

    return `${baggage.maxPieces} ${!!unitTypeText ? unitTypeText : baggage.unit}`;
  };
}
export default LuggageLabelFactory;
