import React, { useEffect, useState } from 'react';

import { LoadingOverlay, PasswordChange as PasswordChangeComponent } from '@ibe/components';

import { Col, Row } from 'reactstrap';
import Keys from '@/Translations/generated/en/password-change.json.keys';
import useTranslationMGL from '@/Util/useTranslationMgl';
import PageUrl from '@/Models/PageUrl';
import { useRouter } from 'next/router';
import useQuery from '@/Util/useQuery';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';

const PasswordChange = () => {
  const { t } = useTranslationMGL('password-change');
  const router = useRouter();

  const [dots, setDots] = useState('.');
  const [allowRedirect, setAllowRedirect] = useState(false);
  const [loading, setLoading] = useState(false);

  const query = useQuery();
  const agency = query.get('agency') || '';
  const login = query.get('login') || '';
  const token = query.get('token') || '';

  const { magnoliaContext } = useGlobalMGLProps() || {};

  useEffect(() => {
    if (allowRedirect) {
      const loop = setInterval(() => {
        setDots(`${dots}.`);
      }, 1000);

      return (): void => clearInterval(loop);
    }
    return (): void => {};
  }, [dots, allowRedirect]);

  useEffect(() => {
    if (dots === '......' || (!(!!agency && !!login && !!token) && !magnoliaContext?.isMagnolia)) {
      router.push(PageUrl.HOME);
    }
  }, [dots, agency, login, token]);

  const onSuccess = (): void => {
    setAllowRedirect(true);
  };

  const onLoading = (value: boolean): void => {
    setLoading(value);
  };

  return (
    <LoadingOverlay isLoading={loading}>
      <div className={'passwordChange'}>
        <Row>
          <Col>
            <h3>{t(Keys.header)}</h3>
            <PasswordChangeComponent
              agency={agency}
              login={login}
              token={token}
              onSuccess={onSuccess}
              loading={onLoading}
              initializeFailedMessage={false}
            />
          </Col>
        </Row>
      </div>
    </LoadingOverlay>
  );
};
export default PasswordChange;
